import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import convertToFormData from "utils/ConvertToFormData";
import { submitFormHandler } from "utils/App.utils";
import ContactInfo from "./contactInfo";
import EarningRedemtion from "./earning-Redemtion";
import MerchantRegion from "./merchantRegion";
import SocialLinks from "./socialLinks";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import FormSectionHeader from "../components/formSectionHeader";
import { getConfiguration } from "actions/configuration/configuration.action";
import { updateConfiguration } from "actions/configuration/configuration.action";
import { resetRegionState } from "actions/regions/regions.actions";
import { getRegions } from "actions/regions/regions.actions";
import Loader from "components/loaderSpinner/Loader";

const initialMerchantData = {
  minimum_redeem_points: 0,
  cashback_percent: 0,
  country_id: "",
  state_id: "",
  city_id: "",
  area_id: "",
  contact_info: {
    phone: "",
    public_phone: "",
    internal_phone: "",
    email: "",
    website: "",
  },
  social_profiles: {
    facebook: "",
    instagram: "",
    twitter: "",
  },
  location: {
    type: "Point",
    coordinates: {
      longitude: "",
      latitude: "",
    },
    name: "",
  },
  address: "",
};

const ConfigurationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, configuration } = useSelector(
    (state) => state.configuration
  );
  const formRef = useRef(null);
  const [lang_id, setLang_id] = useState(null);
  const [merchantData, setMerchantData] = useState(initialMerchantData);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const onChangeFormData = ({ name, value }) => {
    setMerchantData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeLocationInfo = (e) => {
    const { name, value } = e.target;

    if (name === "latitude" || name === "longitude") {
      setMerchantData((prevState) => ({
        ...prevState,
        location: {
          ...prevState.location,
          coordinates: {
            ...prevState.location.coordinates,
            [name]: value,
          },
        },
      }));
    } else if (name === "address") {
      setMerchantData((prevState) => ({
        ...prevState,
        address: value,
        location: {
          ...prevState.location,
          name: value,
        },
      }));
    }
  };

  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  useEffect(() => {
    if (!configuration) return;
    // const { social_profiles, restData } = configuration;
    setMerchantData((prevData) => ({
      ...prevData,
      ...configuration,
      social_profiles: {
        ...initialMerchantData.social_profiles,
        ...configuration?.social_profiles,
      },
      contact_info: {
        ...initialMerchantData.contact_info,
        ...configuration?.contact_info,
      },
      country_id: configuration?.country_id?.id || "",
      state_id: configuration?.state_id?.id || "",
      city_id: configuration?.city_id?.id || "",
      area_id: configuration?.area_id?.id || "",
      category_id: configuration?.category_id?.id || "",
      address: configuration?.address || "",
    }));
  }, [configuration]);

  useEffect(() => {
    if (!configuration) return;
    const { country_id, state_id, city_id } = configuration;
    dispatch(resetRegionState());
    dispatch(
      getRegions(
        country_id?.code,
        state_id?.code,
        country_id?.id,
        state_id?.id,
        city_id?.id
      )
    );
  }, [configuration]);

  const handleSubmit = (e) => {
    e?.preventDefault(e);

    let temp_formData = { ...merchantData };
    // delete the properties from payload that are not allowed to send
    Object.keys(temp_formData).forEach((key) => {
      if (initialMerchantData[key] == undefined) {
        delete temp_formData[key];
      }
    });

    const formData = convertToFormData(temp_formData);
    // handleFormSubmit(formData, shouldRedirect.current);
    dispatch(updateConfiguration(formData));
  };

  return (
    <div className="h-full w-full bg-white">
      {loading ? (
        <div className="flex h-screen w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {showCancelModal ? (
            <ConfirmationModal
              onBack={() => setShowCancelModal(false)}
              onConfirm={() => {
                console.log("Cancel btn presssed");
              }}
              confirmBtnText=""
            >
              <div className="flex flex-col gap-4">
                <h1>Cancel Confirmation</h1>
                <p className="text-sm text-gray-700">
                  Are you sure you want to cancel? All the changes will be
                  lost...!
                </p>
              </div>
            </ConfirmationModal>
          ) : null}

          <CardHeader
            label={`Configuration`}
            buttons={[
              {
                text: "Save",
                onClick: () => {
                  submitFormHandler(formRef.current);
                },
                variant: "secondary",
              },
            ]}
          />

          <div className="flex flex-col gap-4 p-4">
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 p-4"
            >
              {/* {!lang_id ? ( */}
              <Fragment>
                {/* Contact */}
                <FormSectionHeader text="Contact Information" />
                <ContactInfo
                  merchantData={merchantData}
                  onChangeFormData={onChangeFormData}
                />

                {/* Social Profiles */}
                <FormSectionHeader text="Social Profiles" />
                <SocialLinks
                  merchantData={merchantData}
                  onChangeFormData={onChangeFormData}
                />

                {/* Region */}
                <FormSectionHeader text="Region" />
                <MerchantRegion
                  merchantData={merchantData}
                  setMerchantData={setMerchantData}
                  onChangeFormData={onChangeFormData}
                />

                {/* Address Latitude Longitude */}
                <FormSectionHeader text="Location" />
                <div className="flex flex-col justify-between gap-2 md:flex-row">
                  <div className="w-full">
                    <label className="mb-1 block text-base font-medium text-gray-800">
                      Location Latitude
                    </label>
                    <input
                      required
                      type="text"
                      name="latitude"
                      value={merchantData.location.coordinates.latitude}
                      onChange={onChangeLocationInfo}
                      className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                    />
                  </div>
                  <div className="w-full">
                    <label className="mb-1 block text-base font-medium text-gray-800">
                      Location Longitude
                    </label>
                    <input
                      required
                      type="text"
                      name="longitude"
                      value={merchantData.location.coordinates.longitude}
                      onChange={onChangeLocationInfo}
                      className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                    />
                  </div>
                </div>

                {/* Address */}
                <div className="flex flex-col justify-between gap-2 md:flex-row">
                  <div className="w-full">
                    <label className="mb-1 block text-base font-medium text-gray-800">
                      Address
                    </label>
                    <input
                      onChange={onChangeLocationInfo}
                      required
                      type="text"
                      name="address"
                      value={merchantData.address}
                      className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                    />
                  </div>
                </div>

                {/* Earning & Redemption */}
                <FormSectionHeader text="Earning & Redemption" />
                <EarningRedemtion
                  merchantData={merchantData}
                  onChangeFormData={onChangeFormData}
                />
              </Fragment>
              {/* ) : null} */}

              {/* action buttons */}
              <div className="flex justify-between">
                <button
                  disabled={loading}
                  type="submit"
                  onClick={() => handleSubmit()}
                  className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
                >
                  {loading ? "Saving..." : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={() => setShowCancelModal(true)}
                  className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigurationForm;
