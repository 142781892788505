import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import convertToFormData from "utils/ConvertToFormData";
import { FORM_TYPE } from "constants/app.constants";
import CardHeader from "../components/cardHeader";
import { validateCodePhoneNumber } from "utils/App.utils";
import { isValidNumber } from "utils/App.utils";
import { isValidCode } from "utils/App.utils";
import { setAlert } from "actions/alert/alert.actions";
import { AlertTypes } from "reducers/alert/alert.reducer";
import UpdatePassword from "../components/updatePassword";
import { updateBrandPassword } from "actions/Brands/brands.action";
import PhoneNumberInput from "../components/phoneNumberInput";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialBrandData = {
  name: "",
  email: "",
  domain: "",
  phone_number: {
    code: "",
    number: "",
  },
};

const BrandForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, brand } = useSelector((state) => state.brand);
  const [brandData, setBrandData] = useState(initialBrandData);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showUpdatePasswordPopup, setShowUpdatePasswordPopup] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;

    setBrandData((prevData) => ({
      ...prevData,
      ...initialFormData,
      // password: "",
      // confirmPassword: "",
    }));
  }, [initialFormData]);

  const onChangeFormData = (name, value) => {
    if (name === "domain") {
      // Allow only letters, numbers, and dashes
      const domain = value.replace(/[^a-zA-Z-]/g, "");
      setBrandData((prevState) => ({ ...prevState, [name]: domain }));
    } else {
      setBrandData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = (redirect) => {
    const errors = [];
    let payload = { ...brandData };

    const validPhoneNumber = validateCodePhoneNumber(
      payload.phone_number.code,
      payload.phone_number.number
    );
    if (!validPhoneNumber) {
      errors.push(`Phone number is Incorrect`);
    }
    // validate data
    // Object.entries(payload).forEach(([key, value]) => {
    //   if (value === "") {
    //     errors.push(`${key} is required`);
    //   }
    // });

    if (errors.length) {
      return errors.forEach((error) =>
        dispatch(setAlert(error, AlertTypes.ERROR))
      );
    }

    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialBrandData[key] == undefined) {
        delete payload[key];
      }
    });
    if (formType === FORM_TYPE.UPDATE) delete payload.domain;
    // let formData = convertToFormData(payload);
    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.BRAND}
          entityId={brandData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/brands")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to Brands
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Brand`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/brands"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "Update Password",
                  onClick: () => setShowUpdatePasswordPopup(true),
                  variant: "secondary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      {formType === FORM_TYPE.UPDATE && showUpdatePasswordPopup ? (
        <UpdatePassword
          handleSubmit={({ password, confirmPassword }) =>
            dispatch(
              updateBrandPassword(brandData.id, { password, confirmPassword })
            )
          }
          handleCancel={() => setShowUpdatePasswordPopup(false)}
        />
      ) : null}

      <div className="flex flex-col gap-3 p-4">
        {/* name & email */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Brand Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Brand Name"
              value={brandData.name}
              onChange={(e) => onChangeFormData("name", e.target.value)}
              className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="ex. something@gmail.com"
              value={brandData.email}
              onChange={(e) => onChangeFormData("email", e.target.value)}
              className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        </div>

        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex w-full flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Phone Number
            </label>
            <PhoneNumberInput
              phone_number={brandData.phone_number}
              onPhoneNumberChange={(phone_number) =>
                onChangeFormData("phone_number", phone_number)
              }
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Domain Name
            </label>
            <div className="flex w-full items-end justify-center">
              <input
                disabled={formType === FORM_TYPE.UPDATE}
                type="text"
                name="domain"
                placeholder="Type Domain here"
                value={brandData.domain}
                onChange={(e) => onChangeFormData("domain", e.target.value)}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
              />
              {formType === FORM_TYPE.CREATE ? (
                <span className="ml-1 text-base font-medium leading-5 text-gray-400">
                  .mithu.com
                </span>
              ) : null}
            </div>
          </div>
          <div className="flex flex-auto flex-col items-start">
            {" "}
            <label className="mb-1 block text-base font-medium text-gray-800"></label>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandForm;
