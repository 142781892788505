import { getCustomers } from "actions/customers/customers.actions";
import { getStores } from "actions/store/store.actions";
import { getAffiliateOrders } from "actions/affiliateOrders/affiliateOrders.actions";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";
import { PiExport } from "react-icons/pi";
import { exportOrders } from "actions/orders/orders.actions";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import AffiliateOrdersTableCard from "./affiliate-orders-table-card";

const AffiliateOrders = () => {
  const dispatch = useDispatch();
  const { ordersCount, orders, loading } = useSelector(
    (state) => state.affiliateOrders
  );
  const { loading: storesLoading, stores } = useSelector(
    (state) => state.store
  );

  const { customers, loading: customerLoading } = useSelector(
    (state) => state.customer
  );
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [fromDate, setFromDAte] = useState(
    dayjs("1980-01-01").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
  const optionAll = { name: "All", value: "" };

  useEffect(() => {
    dispatch(getStores(page, 1000));
    dispatch(getCustomers(page, 1000));
  }, []);

  const getFilteredOrders = () => {
    setFiltersApplied(true);
    dispatch(
      getAffiliateOrders(
        page,
        limit,
        customerId,
        storeId,
        orderNumber,
        fromDate,
        toDate
      )
    );
  };

  useEffect(() => {
    const prev_filter_state = filtersApplied;
    getFilteredOrders();
    setFiltersApplied(prev_filter_state);
  }, [page]);

  const removeAllFilters = () => {
    setFiltersApplied(false);
    setPage(1);
    setCustomerId("");
    setStoreId("");
    setOrderNumber("");
    setFromDAte(dayjs("1980-01-01").format("YYYY-MM-DD"));
    setToDate(dayjs(new Date()).format("YYYY-MM-DD"));
    dispatch(getAffiliateOrders(1, limit));
  };

  // const exportTransaction = (appFilters) => {
  //   if (!appFilters) dispatch(exportOrders());
  //   else {
  //     dispatch(
  //       exportOrders(
  //         merchantId,
  //         customerId,
  //         fromDate,
  //         toDate
  //       )
  //     );
  //   }
  // };

  return (
    <div className="h-full w-full">
      <AffiliateOrdersTableCard orders={orders}>
        <div className="flex items-end justify-between">
          {/* export buttons */}
          <div className="flex items-center justify-start gap-2 p-2">
            {/* {hasPermission(
              [PERSMISSIONS_TYPES.AFFILIATE_ORDERS_MANAGEMENT],
              PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS
            ) ? (
              <div className="w-auto">
                <button
                  disabled={loading}
                  onClick={() => exportTransaction(false)}
                  className={`flex items-center gap-1 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
                  type="button"
                >
                  <PiExport className="h-4 w-4" />
                  {"Export All"}
                </button>
              </div>
            ) : null}
            {filtersApplied ? (
              <div className="w-auto">
                <button
                  disabled={loading}
                  onClick={() => exportTransaction(true)}
                  className={`flex items-center gap-1 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
                  type="button"
                >
                  <PiExport className="h-4 w-4" />
                  {"Export Filtered Data"}
                </button>
              </div>
            ) : null} */}
          </div>

          {/* filter buttons */}
          <div className="flex items-center justify-end gap-2 p-2">
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={removeAllFilters}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
                type="button"
              >
                <MdFilterAltOff className="h-4 w-4" />
                {"Clear Filters"}
              </button>
            </div>
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={getFilteredOrders}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
                type="button"
              >
                <CiFilter className="h-4 w-4" />
                {"Apply Filters"}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          {/* Stores, Customer and Date */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            {/* stores */}
            <div className="w-full  md:w-1/3">
              <HeadlessSelectObject
                disable={!stores.length || storesLoading}
                title={storesLoading ? "Loading..." : "All Stores"}
                options={[
                  optionAll,
                  ...stores.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={storeId}
                onSelect={setStoreId}
              />
            </div>
            {/* customer */}
            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!customers.length || customerLoading}
                title={customerLoading ? "Loading..." : "All Customers"}
                options={[
                  optionAll,
                  ...customers.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={customerId}
                onSelect={setCustomerId}
              />
            </div>
            {/* start & end date */}
            <div className="flex w-full items-center justify-between gap-2 rounded-md border border-gray-300 p-1 md:w-1/3">
              <input
                className="text-sm"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDAte(e.target.value)}
              />
              <p className="text-lg font-bold">-</p>
              <input
                className="text-sm"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
          {/* order number */}
          <div className="w-full ">
            <input
              onChange={(e) => setOrderNumber(e.target.value)}
              value={orderNumber}
              type="text"
              name="orderNumber"
              placeholder="order Number"
              className="h-full w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
            />
          </div>
        </div>
      </AffiliateOrdersTableCard>
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={ordersCount}
      />
    </div>
  );
};

export default AffiliateOrders;
