import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import HeadlessSelectObject from "../../components/headless/selectObject.headless";
import { getSystemSettings } from "selectors/settings/settings.selector";
import OperationsTableCard from "./operations-table-card";
import { MONTHS } from "constants/app.constants";
import { GetAllOperationReports } from "actions/reports/ReportsActions";

const Operations = () => {
  const dispatch = useDispatch();
  const { reports, loading } = useSelector((state) => state.reports);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const [filtersApplied, setFiltersApplied] = useState(false);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);
  const [months, setMonths] = useState([]);

  const [year, setYear] = useState(currentYear.toString());
  const [month, setMonth] = useState(MONTHS[currentMonth]);

  useEffect(() => {
    const availableMonths =
      year === currentYear.toString()
        ? MONTHS.slice(0, currentMonth + 1)
        : MONTHS;
    setMonths(availableMonths);
  }, [year, currentMonth]);

  useEffect(() => {
    if (year === currentYear.toString()) {
      setMonth(MONTHS[currentMonth]);
    }
  }, [year]);

  const getFilteredTransactions = () => {
    setFiltersApplied(true);
    dispatch(GetAllOperationReports(month, year));
  };

  useEffect(() => {
    const prev_filter_state = filtersApplied;
    getFilteredTransactions();
    setFiltersApplied(prev_filter_state);
  }, [page]);

  const removeAllFilters = () => {
    setFiltersApplied(false);
    setYear(currentYear.toString());
    setMonth(MONTHS[currentMonth]);
    dispatch(GetAllOperationReports());
  };

  return (
    <div className="h-full w-full">
      <OperationsTableCard reports={reports}>
        <div className="flex items-end justify-between">
          {/* export buttons */}
          <div className="flex items-center justify-start gap-2 p-2"></div>

          {/* filter buttons */}
          <div className="flex items-center justify-end gap-2 p-2">
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={removeAllFilters}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
                type="button"
              >
                <MdFilterAltOff className="h-4 w-4" />
                {"Clear Filters"}
              </button>
            </div>
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={getFilteredTransactions}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
                type="button"
              >
                <CiFilter className="h-4 w-4" />
                {"Apply Filters"}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          {/* Month and Year */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            {/* year */}
            <div className="w-full md:w-1/2">
              <HeadlessSelectObject
                disable={!years.length}
                title={"Select Year"}
                options={[
                  // optionAll,
                  ...years.map((year) => ({
                    name: year.toString(),
                    value: year.toString(),
                  })),
                ]}
                selected={year}
                onSelect={setYear}
              />
            </div>

            {/* month */}
            <div className="w-full md:w-1/2">
              <HeadlessSelectObject
                disable={!months.length}
                title={"Select Month"}
                options={[
                  // optionAll,
                  ...months.map((monthName) => ({
                    name:
                      monthName?.charAt(0)?.toUpperCase() + monthName?.slice(1),
                    value: monthName,
                  })),
                ]}
                selected={month}
                onSelect={setMonth}
              />
            </div>
          </div>
        </div>
      </OperationsTableCard>
    </div>
  );
};

export default Operations;
