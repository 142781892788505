//-------- Common Action Types -------------//
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

//-------- Alert Action Types -------------//

export const SET_ALERTS = "SET_ALERTS";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//-------- Auth Action Types -------------//
export const SET_TOKEN = "SET_TOKEN";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_DEVICE_ID = "SET_DEVICE_ID";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";

//-------- Customer Action Types -------------//
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_UPDATE_CUSTOMER = "SET_UPDATE_CUSTOMER";
export const SET_CUSTOMER_LOADING = "SET_CUSTOMER_LOADING";
export const RESET_CUSTOMER_STATE = "RESET_CUSTOMER_STATE";

//-------- Order Action Types -------------//
export const SET_ORDERS = "SET_ORDERS";
export const SET_ORDER_LOADING = "SET_ORDER_LOADING";
export const RESET_ORDER_STATE = "RESET_ORDER_STATE";

//-------- Affiliate Order Action Types -------------//
export const SET_AFFILIATE_ORDERS = "SET_AFFILIATE_ORDERS";
export const SET_AFFILIATE_ORDER_LOADING = "SET_AFFILIATE_ORDER_LOADING";
export const RESET_AFFILIATE_ORDER_STATE = "RESET_AFFILIATE_ORDER_STATE";

//-------- TopUp Action Types -------------//
export const SET_TOPUPS = "SET_TOPUPS";
export const SET_TOPUP_LOADING = "SET_TOPUP_LOADING";
export const RESET_TOPUP_STATE = "RESET_TOPUP_STATE";

//-------- Language Action Types -------------//
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const SET_LANGUAGE_LOADING = "SET_LANGUAGE_LOADING";
export const RESET_LANGUAGE_STATE = "RESET_LANGUAGE_STATE";

//-------- Localization Action Types -------------//
export const SET_LOCALIZATIONS = "SET_LOCALIZATIONS";
export const ADD_LOCALIZATION = "ADD_LOCALIZATION";
export const UPDATE_LOCALIZATION = "UPDATE_LOCALIZATION";
export const DELETE_LOCALIZTION = "DELETE_LOCALIZTION";
export const SET_LOCALIZATION_LOADING = "SET_LOCALIZATION_LOADING";
export const RESET_LOCALIZATION_STATE = "RESET_LOCALIZATION_STATE";

//-------- Brands Action Types -------------//
export const SET_BRANDS = "SET_BRANDS";
export const SET_BRAND = "SET_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";
export const SET_BRAND_LOADING = "SET_BRAND_LOADING";
export const RESET_BRAND_STATE = "RESET_BRAND_STATE";

//-------- Facility Action Types -------------//
export const SET_FACILITIES = "SET_FACILITIES";
export const SET_FACILITY = "SET_FACILITY";
export const UPDATE_FACILITY = "UPDATE_FACILITY";
export const DELETE_FACILITY = "DELETE_FACILITY";
export const SET_FACILITY_LOADING = "SET_FACILITY_LOADING";
export const RESET_FACILITY_STATE = "RESET_FACILITY_STATE";

//-------- Merchant, tag and types Action Types -------------//
export const SET_MERCHANTS = "SET_MERCHANTS";
export const UPDATE_MERCHANT_PUBLISH_FLAG = "UPDATE_MERCHANT_PUBLISH_FLAG";
export const SET_MERCHANT = "SET_MERCHANT";
export const DELETE_MERCHANT = "DELETE_MERCHANT";

export const SET_MERCHANT_TAGS = "SET_MERCHANT_TAGS";
export const SET_MERCHANT_TAG = "SET_MERCHANT_TAG";
export const ADD_MERCHANT_TAG = "ADD_MERCHANT_TAG";
export const UPDATE_MERCHANT_TAG = "UPDATE_MERCHANT_TAG";
export const DELETE_MERCHANT_TAG = "DELETE_MERCHANT_TAG";

export const SET_MERCHANT_TYPES = "SET_MERCHANT_TYPES";
export const SET_MERCHANT_TYPE = "SET_MERCHANT_TYPE";
export const ADD_MERCHANT_TYPE = "ADD_MERCHANT_TYPE";
export const UPDATE_MERCHANT_TYPE = "UPDATE_MERCHANT_TYPE";
export const DELETE_MERCHANT_TYPE = "DELETE_MERCHANT_TYPE";

export const SET_MERCHANT_LOADING = "SET_MERCHANT_LOADING";
export const RESET_MERCHANT_STATE = "RESET_MERCHANT_STATE";

//-------- Merchant Promotion Tags Action Types -------------//
export const SET_MERCHANT_PROMOTION_TAGS = "SET_MERCHANT_PROMOTIONS";
export const SET_MERCHANT_PROMOTION_TAG = "SET_MERCHANT_PROMOTION_TAG";
export const DELETE_MERCHANT_PROMOTION_TAG = "DELETE_MERCHANT_PROMOTION_TAG";
export const SET_MERCHANT_PROMOTION_TAG_LOADING =
  "SET_MERCHANT_PROMOTION_TAG_LOADING";
export const RESET_MERCHANT_PROMOTION_TAG_STATE =
  "RESET_MERCHANT_PROMOTION_TAG_STATE";

//-------- Merchant Vibes Action Types -------------//
export const SET_MERCHANT_VIBES = "SET_MERCHANT_VIBES";
export const SET_MERCHANT_VIBE = "SET_MERCHANT_VIBE";
export const SET_MERCHANT_VIBE_LIKED_BY = "SET_MERCHANT_VIBE_LIKED_BY";
export const SET_MERCHANT_VIBE_SEEN_BY = "SET_MERCHANT_VIBE_SEEN_BY";
export const DELETE_VIBE = "DELETE_VIBE";

//-------- cart rules Action Types -------------//
export const SET_VIBE_CATEGORIES = "SET_VIBE_CATEGORIES";
export const SET_VIBE_CATEGORY = "SET_VIBE_CATEGORY";
export const UPDATE_VIBE_CATEGORY_PUBLISH_STATUS =
  "UPDATE_VIBE_CATEGORY_PUBLISH_STATUS";
export const DELETE_VIBE_CATEGORY = "DELETE_VIBE_CATEGORY";
export const SET_VIBE_CATEGORY_LOADING = "SET_VIBE_CATEGORY_LOADING";
export const RESET_VIBE_CATEGORY_STATE = "RESET_VIBE_CATEGORY_STATE";

//-------- Region Action Types -------------//
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_STATES = "SET_STATES";
export const SET_CITIES = "SET_CITIES";
export const SET_AREAS = "SET_AREAS";
export const SET_AREA = "SET_AREA";
export const DELETE_AREA = "DELETE_AREA";
export const SET_REGION_LOADING = "SET_REGION_LOADING";
export const RESET_REGION_STATE = "RESET_REGION_STATE";

//-------- User Action Types -------------//
export const SET_USERS = "SET_USERS";
export const SET_USER = "SET_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const RESET_USER_STATE = "RESET_USER_STATE";

//-------- Transaction Action Types -------------//
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const SET_TRANSACTION_LOADING = "SET_TRANSACTION_LOADING";
export const RESET_TRANSACTION_STATE = "RESET_TRANSACTION_STATE";

//-------- Sliders Action Types -------------//
export const SET_SLIDERS = "SET_SLIDERS";
export const SET_SLIDER = "SET_SLIDER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const SET_SLIDER_LOADING = "SET_SLIDER_LOADING";
export const RESET_SLIDER_STATE = "RESET_SLIDER_STATE";

//-------- FAQs Action Types -------------//
export const SET_FAQS = "SET_FAQS";
export const SET_FAQ = "SET_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const SET_FAQ_LOADING = "SET_FAQ_LOADING";
export const RESET_FAQ_STATE = "RESET_FAQ_STATE";

//-------- Categories Action Types -------------//
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORY = "SET_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const SET_CATEGORY_LOADING = "SET_CATEGORY_LOADING";
export const RESET_CATEGORY_STATE = "RESET_CATEGORY_STATE";

//-------- Tickets Action Types -------------//
export const SET_TICKETS = "SET_TICKETS";
export const SET_TICKET_TYPES = "SET_TICKET_TYPES";
export const SET_TICKET = "SET_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const DELETE_TICKET = "DELETE_TICKET";
export const SET_TICKET_LOADING = "SET_TICKET_LOADING";
export const RESET_TICKET_STATE = "RESET_TICKET_STATE";

//-------- Customer Classification Types -------------//
export const SET_CUSTOMER_CLASSIFICATIONS = "SET_CUSTOMER_CLASSIFICATIONS";
export const SET_CUSTOMER_CLASSIFICATION = "SET_CUSTOMER_CLASSIFICATION";
export const UPDATE_CUSTOMER_CLASSIFICATION = "UPDATE_CUSTOMER_CLASSIFICATION";
export const DELETE_CUSTOMER_CLASSIFICATION = "DELETE_CUSTOMER_CLASSIFICATION";
export const SET_CUSTOMER_CLASSIFICATION_LOADING =
  "SET_CUSTOMER_CLASSIFICATION_LOADING";
export const RESET_CUSTOMER_CLASSIFICATION_STATE =
  "RESET_CUSTOMER_CLASSIFICATION_STATE";

//-------- Store Loyalties Action Types -------------//
export const SET_STORE_LOYALTIES = "SET_STORE_LOYALTIES";
export const SET_STORE_LOYALTY = "SET_STORE_LOYALTY";
export const DELETE_STORE_LOYALTY = "DELETE_STORE_LOYALTY";
export const SET_STORE_LOYALTY_LOADING = "SET_STORE_LOYALTY_LOADING";
export const RESET_STORE_LOYALTY_STATE = "RESET_STORE_LOYALTY_STATE";

//-------- Membership Claims Action Types -------------//
export const SET_CLAIMS = "SET_CLAIMS";
export const SET_CLAIMS_LOADING = "SET_CLAIMS_LOADING";
export const RESET_CLAIMS_STATE = "RESET_CLAIMS_STATE";

//-------- Currency Action Types -------------//
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_CURRENCY = "SET_CURRENCY";
export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const SET_CURRENCY_LOADING = "SET_CURRENCY_LOADING";
export const RESET_CURRENCY_STATE = "RESET_CURRENCY_STATE";

//-------- Crypto Currency Action Types -------------//
export const SET_CRYPTO_CURRENCIES = "SET_CRYPTO_CURRENCIES";
export const SET_CRYPTO_CURRENCY = "SET_CRYPTO_CURRENCY";
export const DELETE_CRYPTO_CURRENCY = "DELETE_CRYPTO_CURRENCY";
export const SET_CRYPTO_CURRENCY_LOADING = "SET_CRYPTO_CURRENCY_LOADING";
export const RESET_CRYPTO_CURRENCY_STATE = "RESET_CURRENCY_STATE";

//-------- MDashboard Action Types -------------//
export const SET_DASHBOARD_STATS = "SET_DASHBOARD_STATS";
export const SET_DASHBOARD_LOADING = "SET_DASHBOARD_LOADING";
export const RESET_DASHBOARD_STATE = "RESET_DASHBOARD_STATE";

//-------- Roles Action Types -------------//
export const SET_ROLES = "SET_ROLES";
export const SET_ROLE_PERMISSIONS = "SET_ROLE_PERMISSIONS";
export const SET_ROLE = "SET_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const SET_ROLE_LOADING = "SET_ROLE_LOADING";
export const RESET_ROLE_STATE = "RESET_ROLE_STATE";

//-------- configuration Action Types -------------//
export const SET_CONFIGURATION = "SET_CONFIGURATION";
export const SET_CONFIGURATION_LOADING = "SET_CONFIGURATION_LOADING";
export const RESET_CONFIGURATION_STATE = "RESET_CONFIGURATION_STATE";

//-------- Ratings Action Types -------------//
export const SET_RATINGS = "SET_RATINGS";
export const SET_RATING = "SET_RATING";
export const UPDATE_SET_RATING = "UPDATE_SET_RATING";
export const SET_RATING_LOADING = "SET_RATING_LOADING";
export const RESET_RATING_STATE = "RESET_RATING_STATE";

//-------- cart rules Action Types -------------//
export const SET_CART_RULES = "SET_CART_RULES";
export const SET_CART_RULE = "SET_CART_RULE";
export const UPDATE_CART_RULE_PUBLISH_STATUS =
  "UPDATE_CART_RULE_PUBLISH_STATUS";
export const DELETE_CART_RULE = "DELETE_CART_RULE";
export const SET_CART_RULE_LOADING = "SET_CART_RULE_LOADING";
export const RESET_CART_RULE_STATE = "RESET_CART_RULE_STATE";

//-------- push  notifications Action Types -------------//
export const SET_PUSH_NOTIFICATIONS = "SET_PUSH_NOTIFICATIONS";
export const SET_PUSH_NOTIFICATION = "SET_PUSH_NOTIFICATION";
export const SET_PUSH_NOTIFICATION_LOADING = "SET_PUSH_NOTIFICATION_LOADING";
export const RESET_PUSH_NOTIFICATION_STATE = "RESET_PUSH_NOTIFICATION_STATE";

//-------- settings Action Types -------------//
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_SYSTEM_SETTINGS = "SET_SYSTEM_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SET_SETTING = "SET_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const DELETE_SETTING = "DELETE_SETTING";
export const SET_SETTING_LOADING = "SET_SETTING_LOADING";
export const RESET_SETTING_STATE = "RESET_SETTING_STATE";

//-------- Devices Action Types -------------//
export const SET_DEVICES = "SET_DEVICES";
export const SET_DEVICE = "SET_DEVICE";
export const UPDATE_DEVICE_ENABLE_FLAG = "UPDATE_DEVICE_ENABLE_FLAG";
export const UPDATE_DEVICE_INTEGRATION_FLAG = "UPDATE_DEVICE_INTEGRATION_FLAG";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const SET_DEVICE_LOADING = "SET_DEVICE_LOADING";
export const RESET_DEVICE_STATE = "RESET_DEVICE_STATE";

//-------- EVENTS -------------//
export const SET_ACTIVE_USERS_EVENT = "SET_ACTIVE_USERS_EVENT";
export const SET_NEW_ORDER_EVENT = "SET_NEW_ORDER_EVENT";
export const SET_NEW_TRANSACTION_EVENT = "SET_NEW_TRANSACTION_EVENT";
export const SET_NEW_CUSTOMER_EVENT = "SET_NEW_CUSTOMER_EVENT";
export const SET_MERCHANT_EVENT = "SET_MERCHANT_EVENT";
export const SET_NEW_MERBERSHIP_EVENT = "SET_NEW_MERBERSHIP_EVENT";
export const RESET_EVENTS_STATE = "RESET_EVENTS_STATE";

//-------- activity Logs -------------//
export const SET_LOGS = "SET_LOGS";
export const SET_LOG_LOADING = "SET_LOG_LOADING";
export const RESET_LOG_STATE = "RESET_LOG_STATE";


//-------- Store Categories Action Types -------------//
export const SET_STORE_CATEGORIES = "SET_STORE_CATEGORIES";
export const SET_STORE_CATEGORY = "SET_STORE_CATEGORY";
export const UPDATE_STORE_CATEGORY = "UPDATE_STORE_CATEGORY";
export const DELETE_STORE_CATEGORY = "DELETE_STORE_CATEGORY";
export const SET_STORE_CATEGORY_LOADING = "SET_STORE_CATEGORY_LOADING";
export const RESET_STORE_CATEGORY_STATE = "RESET_STORE_CATEGORY_STATE";


//-------- Store Action Types -------------//
export const SET_STORES = "SET_STORES";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const SET_STORE = "SET_STORE";
export const UPDATE_STORE = "UPDATE_STORE";
export const DELETE_STORE = "DELETE_STORE";
export const SET_STORE_LOADING = "SET_STORE_LOADING";
export const RESET_STORE_STATE = "RESET_STORE_STATE";

//-------- StoreTags Action Types -------------//
export const SET_STORE_TAGS = "SET_STORE_TAGS";
export const SET_STORE_TAG = "SET_STORE_TAG";
export const UPDATE_STORE_TAG = "UPDATE_STORE_TAG";
export const DELETE_STORE_TAG = "DELETE_STORE_TAG";
export const SET_STORE_TAG_LOADING = "SET_STORE_TAG_LOADING";
export const RESET_STORE_TAG_STATE = "RESET_STORE_TAG_STATE";

//-------- reports Action Types -------------//
export const SET_REPORTS = "SET_REPORTS";
// export const SET_REPORT = "SET_REPORT";
// export const UPDATE_REPORT = "UPDATE_REPORT";
// export const DELETE_REPORT = "DELETE_REPORT";
export const SET_REPORT_LOADING = "SET_REPORT_LOADING";
export const RESET_REPORT_STATE = "RESET_REPORT_STATE";
