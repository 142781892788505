import { getAreas } from "actions/regions/regions.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AreasTableCard from "./areas-table-card";
import Pagination from "views/admin/components/pagination";
import { resetRegionState } from "actions/regions/regions.actions";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Areas = () => {
  const dispatch = useDispatch();
  const { areasCount, areas, loading } = useSelector((state) => state.region);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  useEffect(() => {
    dispatch(resetRegionState());
    // dispatch(getAreas(page, limit));
  }, []);

  return (
    <div className="h-full w-full">
      <AreasTableCard areas={areas}
        page={page} />

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={areasCount}
      />
    </div>
  );
};

export default Areas;
