import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

export const getTopUps = (
  page = 1,
  limit = 50,
  customer_id,
  topup_number,
  startDate,
  endDate
) => {
  const customer = customer_id ? `&customer_id=${customer_id}` : "";
  const topUpNumber = topup_number ? `&topup_number=${topup_number}` : "";
  const start_date = startDate ? `&startDate=${startDate}` : "";
  const end_date = endDate ? `&endDate=${endDate}` : "";
  return RestClient.Get(
    `${API_URL.GET_TOPUPS}?page=${page}&limit=${limit}${customer}${topUpNumber}${start_date}${end_date}`
  );
};

// export const exportTopUps = (
//   merchant_id,
//   customer_id,
//   country_id,
//   state_id,
//   city_id,
//   startDate,
//   endDate
// ) => {
//   const merchant = merchant_id ? `&merchant_id=${merchant_id}` : "";
//   const customer = customer_id ? `&customer_id=${customer_id}` : "";
//   const country = country_id ? `&country_id=${country_id}` : "";
//   const state = state_id ? `&state_id=${state_id}` : "";
//   const city = city_id ? `&city_id=${city_id}` : "";
//   const start_date = startDate ? `&startDate=${startDate}` : "";
//   const end_date = endDate ? `&endDate=${endDate}` : "";
//   return RestClient.Get(
//     `${API_URL.EXPORT_TOPUPS}?${merchant}${customer}${country}${state}${city}${start_date}${end_date}`
//   );
// };

const exportedObject = {
  getTopUps,
  // exportTopUps,
};

export default exportedObject;
