// Admin Imports
import MainDashboard from "views/admin/default";

import Customers from "views/admin/customers";

import Users from "views/admin/users";
import CreateUser from "views/admin/users/createUser";
import UpdateUser from "views/admin/users/updateUser";

import UserSettings from "views/admin/userProfile/userSettings";

import Roles from "views/admin/roles";

import Merchants from "views/admin/merchants";
import CreateNewMerchant from "views/admin/merchants/createMerchant";
import UpdateMerchant from "views/admin/merchants/updateMerchant";

import MerchantTypes from "views/admin/merchant-types";
import CreateMerchantTypes from "views/admin/merchant-types/createMerchantType";
import UpdateMerchantTypes from "views/admin/merchant-types/updateMerchantType";

import MerchantTags from "views/admin/merchant-tags";
import CreateMerchantTag from "views/admin/merchant-tags/createMerchantTag";
import UpdateMerchantTag from "views/admin/merchant-tags/updateMerchantTag";

import Languages from "views/admin/languages";
import Transactions from "views/admin/transactions";

import Faqs from "views/admin/faq";
import CreateFaq from "views/admin/faq/createFaq";
import UpdateFaq from "views/admin/faq/updateFaq";

import Slider from "views/admin/slider";
import CreateSlider from "views/admin/slider/createSlider";
import UpdateSlider from "views/admin/slider/updateSlider";

import Categories from "views/admin/categories";
import CreateCategory from "views/admin/categories/createCategory";
import UpdateCategory from "views/admin/categories/updateCategory";

import Tickets from "views/admin/tickets";
import CreateTicket from "views/admin/tickets/createTickets";
import UpdateTicket from "views/admin/tickets/updateTickets";

import CustomerClassification from "views/admin/customer-classifications";
import CreateClassification from "views/admin/customer-classifications/createClassification";
import UpdateClassification from "views/admin/customer-classifications/updateClassification";

import Countries from "views/admin/regions/countries";

import Areas from "views/admin/regions/areas";
import CreateArea from "views/admin/regions/areas/createArea";
import UpdateArea from "views/admin/regions/areas/updateArea";

import MembershipClaim from "views/admin/membership-claim";

import Currencies from "views/admin/currencies";
import CreateCurrency from "views/admin/currencies/createCurrency";
import UpdateCurrency from "views/admin/currencies/updateCurrency";

import CryptoCurrencies from "views/admin/crypto-currencies";
import CreateCryptoCurrency from "views/admin/crypto-currencies/createCurrency";
import UpdateCryptoCurrency from "views/admin/crypto-currencies/updateCurrency";

import StoreLoyalty from "views/admin/storeLoyalty";
import CreateStoreLoyalty from "views/admin/storeLoyalty/createStoreLoyalty";
import UpdateStoreLoyalty from "views/admin/storeLoyalty/updateStoreLoyalty";

import Cities from "views/admin/regions/cities";
import StateCities from "views/admin/regions/countries/cities";
import CountryStates from "views/admin/regions/countries/states";
import States from "views/admin/regions/states";

import Facility from "views/admin/facilities";
import CreateFacility from "views/admin/facilities/createFacility";
import UpdateFacility from "views/admin/facilities/updateFacility";

import Brand from "views/admin/brands";
import CreateBrand from "views/admin/brands/createBrand";
import UpdateBrand from "views/admin/brands/updateBrand";

import CartRules from "views/admin/cartRules";
import CreateCartRule from "views/admin/cartRules/createCartRule";
import UpdateCartRule from "views/admin/cartRules/updateCartRule";

import ConfigurationForm from "views/admin/configurationForm";

import CustomerProfile from "views/admin/customers/profile";

import CreateNewLanguage from "views/admin/languages/createLanguage";
import UpdateLanguage from "views/admin/languages/updateLanguage";

import MerchantPromotionTags from "views/admin/merchant-promotion-tags";
import CreateMerchantPromotionTag from "views/admin/merchant-promotion-tags/createMerchantPromotionTag";
import UpdateMerchantPromotionTag from "views/admin/merchant-promotion-tags/updateMerchantPromotionTag";

import Orders from "views/admin/orders";

import PushNotifications from "views/admin/push-notifications";
import CreatePushNotification from "views/admin/push-notifications/createPushNotification";

import Ratings from "views/admin/ratings";
import UpdateRating from "views/admin/ratings/updateRating";

import Vibes from "views/admin/vibes";
import CreateVibe from "views/admin/vibes/createVibe";
import UpdateVibe from "views/admin/vibes/updateVibe";

import Settings from "views/admin/settings";

import Devices from "views/admin/devices";
import CreateDevice from "views/admin/devices/createDevice";
import UpdateDevice from "views/admin/devices/updateDevice";

import StoreCategories from "views/admin/storeCategories";
import CreateStoreCategory from "views/admin/storeCategories/createStoreCategory";
import UpdateStoreCategory from "views/admin/storeCategories/updateStoreCategory";

import Store from "views/admin/store";
import CreateStore from "views/admin/store/createStore";
import UpdateStore from "views/admin/store/updateStore";

import StoreTags from "views/admin/storeTags";
import UpdateStoreTag from "views/admin/storeTags/updateStoreTag";
import CreateStoreTag from "views/admin/storeTags/createStoreTag";

import AffiliateOrders from "views/admin/affiliateOrders";
import TopUps from "views/admin/topUps";

import Restaurants from "views/admin/reports/restaurants";
import Operations from "views/admin/reports/operations";

//-----------------------------------------Auth Imports--------------------------
import {
  PERMISSION_ACTION_TYPES,
  PERSMISSIONS_TYPES,
} from "constants/role.constants";
// --------------------------------------------------------------------------icons----------------------------------------------------------
// Icon Imports
import ForgotPassword from "pages/forgotPassword";
import Login from "pages/login";
import ResetPassword from "pages/resetPassword";
import ValidateOtp from "pages/validateOtp";
import {
  BsFillQuestionSquareFill,
  BsPersonWorkspace,
  BsSliders,
  BsTicketDetailedFill,
} from "react-icons/bs";
import {
  FaBorderAll,
  FaHandsHelping,
  FaLayerGroup,
  FaMapMarkedAlt,
  FaMoneyBillWave,
  FaRegListAlt,
  FaShoppingBasket,
  FaTags,
} from "react-icons/fa";
import {
  GiCardExchange,
  GiPriceTag,
  GiProfit,
  GiProgression,
  GiRoundStar,
} from "react-icons/gi";
import {
  IoLanguageSharp,
  IoNotificationsSharp,
  IoSettingsSharp,
  IoStorefront,
} from "react-icons/io5";
import {
  MdAddBusiness,
  MdBarChart,
  MdCategory,
  MdContentCopy,
  MdGroupAdd,
  MdHome,
  MdLock,
  MdOutlineCardMembership,
  MdPerson,
} from "react-icons/md";
import { RiArrowUpDownFill, RiWhatsappFill } from "react-icons/ri";
import { SiAlwaysdata, SiBitcoinsv, SiGooglemaps } from "react-icons/si";
import { SlBadge } from "react-icons/sl";
import {
  TbAffiliate,
  TbSettingsCog,
  TbShoppingCartDollar,
} from "react-icons/tb";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { BiSolidDevices } from "react-icons/bi";
import { HiDocumentReport } from "react-icons/hi";
import WhatsappNotificationForm from "views/admin/whatsapp-notifications-form";
import { PiSpeakerHighFill } from "react-icons/pi";

// -----------------------------------------------------------------------------------------------Routes--------------------------
const routes = [
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.REPORTING_AND_ANALYTICS],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <MdHome className="h-4 w-4" />,
    component: <MainDashboard />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.CUSTOMER_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Customers",
    layout: "/admin",
    icon: <BsPersonWorkspace className="h-4 w-4" />,
    path: "/customers",
    component: <Customers />,
    children: [
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.CUSTOMER_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Customer Profile",
        layout: "/admin",
        path: "/customer/profile/:customerId",
        component: <CustomerProfile />,
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Merchants",
    layout: "/admin",
    icon: <MdAddBusiness className="h-4 w-4" />,
    path: "/merchants",
    component: <Merchants />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Brands",
        layout: "/admin",
        icon: <SlBadge className="h-3 w-3" />,
        path: "/brands",
        component: <Brand />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Merchant",
            layout: "/admin",
            path: "/brands/create",
            component: <CreateBrand />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Merchant",
            layout: "/admin",
            path: "/brands/update/:brand_id",
            component: <UpdateBrand />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "List",
        layout: "/admin",
        icon: <FaRegListAlt className="h-3 w-3" />,
        path: "/merchants",
        component: <Merchants />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Merchant",
            layout: "/admin",
            path: "/merchants/create",
            component: <CreateNewMerchant />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Merchant",
            layout: "/admin",
            path: "/merchants/update/:merchantId",
            component: <UpdateMerchant />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Types",
        layout: "/admin",
        icon: <VscTypeHierarchySub className="h-3 w-3" />,
        path: "/merchant-types",
        component: <MerchantTypes />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Merchant Types",
            layout: "/admin",
            path: "/merchant-types/create",
            component: <CreateMerchantTypes />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Merchant Types",
            layout: "/admin",
            path: "/merchant-types/update/:merchantTypeId",
            component: <UpdateMerchantTypes />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Tags",
        layout: "/admin",
        icon: <FaTags className="h-3 w-3" />,
        path: "/merchant-tags",
        component: <MerchantTags />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Merchant Tags",
            layout: "/admin",
            path: "/merchant-tags/create",
            component: <CreateMerchantTag />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Merchant Tags",
            layout: "/admin",
            path: "/merchant-tags/update/:merchantTagId",
            component: <UpdateMerchantTag />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Promotion Message",
        layout: "/admin",
        icon: <GiPriceTag className="h-3 w-3" />,
        path: "/merchant-promotion-tags",
        component: <MerchantPromotionTags />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Merchant Promotion Message",
            layout: "/admin",
            path: "/merchant-promotion-tags/create",
            component: <CreateMerchantPromotionTag />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Merchant Promotion Message",
            layout: "/admin",
            path: "/merchant-promotion-tags/update/:merchantPromotionTagId",
            component: <UpdateMerchantPromotionTag />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Facilities",
        layout: "/admin",
        icon: <FaHandsHelping className="h-3 w-3" />,
        path: "/facility",
        component: <Facility />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Merchant Promotion Message",
            layout: "/admin",
            path: "/facility/create",
            component: <CreateFacility />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Merchant Promotion Message",
            layout: "/admin",
            path: "/facility/update/:facilityId",
            component: <UpdateFacility />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
        name: "Vibes",
        layout: "/admin",
        icon: <FaRegListAlt className="h-3 w-3" />,
        path: "/vibes",
        component: <Vibes />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Create Vibe",
            layout: "/admin",
            path: "/vibes/create",
            component: <CreateVibe />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Vibe",
            layout: "/admin",
            path: "/vibes/update/:vibeId",
            component: <UpdateVibe />,
          },
        ],
      },

      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.DEVICE_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Devices",
        layout: "/admin",
        icon: <BiSolidDevices className="h-4 w-4" />,
        path: "/devices",
        component: <Devices />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.DEVICE_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Device",
            layout: "/admin",
            path: "/devices/create",
            component: <CreateDevice />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.DEVICE_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Device",
            layout: "/admin",
            path: "/devices/update/:deviceId",
            component: <UpdateDevice />,
          },
        ],
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Store Loyalty",
    layout: "/admin",
    icon: <MdOutlineCardMembership className="h-4 w-4" />,
    path: "/storeLoyalty",
    component: <StoreLoyalty />,
    children: [
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
        name: "Create",
        layout: "/admin",
        path: "/storeLoyalty/create",
        component: <CreateStoreLoyalty />,
      },
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
        name: "Update",
        layout: "/admin",
        path: "/storeLoyalty/update/:storeloyalty_id",
        component: <UpdateStoreLoyalty />,
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.ORDERS_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Orders",
    layout: "/admin",
    icon: <FaShoppingBasket className="h-4 w-4" />,
    path: "/orders",
    component: <Orders />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Affiliate Orders",
    layout: "/admin",
    icon: <TbAffiliate className="h-4 w-4" />,
    path: "/affiliateOrders",
    component: <AffiliateOrders />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.TOPUPS_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "TopUps",
    layout: "/admin",
    icon: <GiProfit className="h-4 w-4" />,
    path: "/topUps",
    component: <TopUps />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.TRANSACTION_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Transactions",
    layout: "/admin",
    icon: <RiArrowUpDownFill className="h-4 w-4" />,
    path: "/transactions",
    component: <Transactions />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.USER_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Users",
    layout: "/admin",
    // icon: <img className="h-5 w-5 " src={merchantIcon} alt="" />,
    icon: <MdPerson className="h-4 w-4" />,
    path: "/users",
    component: <Users />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.USER_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "List",
        layout: "/admin",
        icon: <FaRegListAlt className="h-3 w-3" />,
        path: "/users",
        component: <Users />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.USER_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create User",
            layout: "/admin",
            icon: <MdBarChart className="h-3 w-3" />,
            path: "/users/create",
            component: <CreateUser />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.USER_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update User",
            layout: "/admin",
            icon: <MdBarChart className="h-3 w-3" />,
            path: "/users/update/:userId",
            component: <UpdateUser />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.USER_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Roles",
        layout: "/admin",
        icon: <MdGroupAdd className="h-3 w-3" />,
        path: "/roles",
        component: <Roles />,
        children: [],
      },
    ],
  },
  {
    isShowInSideNav: false,
    requiredRoles: [PERSMISSIONS_TYPES.USER_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "User Profile",
    layout: "/admin",
    icon: <MdPerson className="h-4 w-4" />,
    path: "userProfile/setting/:userId",
    component: <UserSettings />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.LANGUAGE_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Languages",
    layout: "/admin",
    icon: <IoLanguageSharp className="h-4 w-4" />,
    path: "/languages",
    component: <Languages />,
    children: [
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.LANGUAGE_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
        name: "Create Language",
        layout: "/admin",
        path: "/languages/create",
        component: <CreateNewLanguage />,
      },
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.LANGUAGE_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
        name: "Update Language",
        layout: "/admin",
        path: "/languages/update/:language_id",
        component: <UpdateLanguage />,
      },
    ],
  },
  {
    isShowInSideNav: false,
    isPublic: true,
    name: "Sign In",
    layout: "/auth",
    path: "signin",
    icon: <MdLock className="h-4 w-4" />,
    component: <Login />,
  },
  {
    isShowInSideNav: false,
    isPublic: true,
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-4 w-4" />,
    component: <ForgotPassword />,
  },
  {
    isShowInSideNav: false,
    isPublic: true,
    name: "Validate OTP",
    layout: "/auth",
    path: "validate-otp",
    icon: <MdLock className="h-4 w-4" />,
    component: <ValidateOtp />,
  },
  {
    isShowInSideNav: false,
    isPublic: true,
    name: "Reset Password",
    layout: "/auth",
    path: "reset-password",
    icon: <MdLock className="h-4 w-4" />,
    component: <ResetPassword />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [
      PERSMISSIONS_TYPES.SLIDER_MANAGEMENT,
      PERSMISSIONS_TYPES.CATEGORY_MANAGEMENT,
      PERSMISSIONS_TYPES.FAQS,
    ],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "App Content",
    layout: "/admin",
    path: "/sliders",
    icon: <MdContentCopy className="h-4 w-4" />,
    component: <Slider />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.SLIDER_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Sliders",
        layout: "/admin",
        icon: <BsSliders className="h-3 w-3" />,
        path: "/sliders",
        component: <Slider />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.SLIDER_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Slider",
            layout: "/admin",
            path: "/sliders/create",
            component: <CreateSlider />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.SLIDER_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Slider",
            layout: "/admin",
            path: "/sliders/update/:sliderId",
            component: <UpdateSlider />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.FAQS],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "FAQs",
        layout: "/admin",
        icon: <BsFillQuestionSquareFill className="h-3 w-3" />,
        path: "/faqs",
        component: <Faqs />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.FAQS],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create FAQs",
            layout: "/admin",
            path: "/faqs/create",
            component: <CreateFaq />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.FAQS],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update FAQs",
            layout: "/admin",
            path: "/faqs/update/:faqId",
            component: <UpdateFaq />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.CATEGORY_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Categories",
        layout: "/admin",
        icon: <FaLayerGroup className="h-3 w-3" />,
        path: "/categories",
        component: <Categories />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CATEGORY_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Category",
            layout: "/admin",
            path: "/categories/create",
            component: <CreateCategory />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CATEGORY_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Category",
            layout: "/admin",
            path: "/categories/update/:categoryId",
            component: <UpdateCategory />,
          },
        ],
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Affiliate Marketing",
    layout: "/admin",
    path: "/stores",
    icon: <TbAffiliate className="h-4 w-4" />,
    component: <Store />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Stores",
        layout: "/admin",
        icon: <IoStorefront className="h-3 w-3" />,
        path: "/stores",
        component: <Store />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Store",
            layout: "/admin",
            path: "/stores/create",
            component: <CreateStore />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Store",
            layout: "/admin",
            path: "/stores/update/:storeId",
            component: <UpdateStore />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Store Categories",
        layout: "/admin",
        icon: <FaLayerGroup className="h-3 w-3" />,
        path: "/storeCategories",
        component: <StoreCategories />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Store Category",
            layout: "/admin",
            path: "/storeCategories/create",
            component: <CreateStoreCategory />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Store Category",
            layout: "/admin",
            path: "/storeCategories/update/:categoryId",
            component: <UpdateStoreCategory />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Store Tags",
        layout: "/admin",
        icon: <FaTags className="h-3 w-3" />,
        path: "/storeTags",
        component: <StoreTags />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Store Tag",
            layout: "/admin",
            path: "/storeTags/create",
            component: <CreateStoreTag />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Store Tag",
            layout: "/admin",
            path: "/storeTags/update/:tagId",
            component: <UpdateStoreTag />,
          },
        ],
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Regions",
    layout: "/admin",
    icon: <SiGooglemaps className="h-4 w-4" />,
    path: "/countries",
    component: <Countries />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Countries",
        layout: "/admin",
        icon: <FaMapMarkedAlt className="h-3 w-3" />,
        path: "/countries",
        component: <Countries />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
            name: "States",
            layout: "/admin",
            path: "/countries/:countryCode/states",
            component: <CountryStates />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
            name: "Cities",
            layout: "/admin",
            path: "/countries/:countryCode/states/:stateCode/cities",
            component: <StateCities />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "States",
        layout: "/admin",
        icon: <FaMapMarkedAlt className="h-3 w-3" />,
        path: "/states",
        component: <States />,
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Cities",
        layout: "/admin",
        icon: <FaMapMarkedAlt className="h-3 w-3" />,
        path: "/cities",
        component: <Cities />,
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Areas",
        layout: "/admin",
        icon: <FaMapMarkedAlt className="h-3 w-3" />,
        path: "/areas",
        component: <Areas />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Area",
            layout: "/admin",
            path: "/areas/create",
            component: <CreateArea />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Area",
            layout: "/admin",
            path: "/areas/update/:areaId",
            component: <UpdateArea />,
          },
        ],
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.CUSTOMER_SUPPORT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Tickets",
    layout: "/admin",
    icon: <BsTicketDetailedFill className="h-4 w-4" />,
    path: "/tickets",
    component: <Tickets />,
    children: [
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.CUSTOMER_SUPPORT],
        requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
        name: "Create Ticket",
        layout: "/admin",
        icon: <MdPerson className="activeRoutew-6 h-6" />,
        path: "/tickets/create",
        component: <CreateTicket />,
      },
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.CUSTOMER_SUPPORT],
        requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
        name: "Update Ticket",
        layout: "/admin",
        icon: <MdPerson className="h-4 w-4" />,
        path: "/tickets/update/:ticket_id",
        component: <UpdateTicket />,
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.REPORTING_AND_ANALYTICS],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Performance Reports",
    layout: "/admin",
    icon: <HiDocumentReport className="h-4 w-4" />,
    path: "/reports",
    // component: <Operations />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.REPORTING_AND_ANALYTICS],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Operations ",
        layout: "/admin",
        icon: <SiAlwaysdata className="h-3 w-3" />,
        path: "/reports/operations",
        component: <Operations />,
        // children: [],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.REPORTING_AND_ANALYTICS],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Restaurants ",
        layout: "/admin",
        icon: <GiProgression className="h-3 w-3" />,
        path: "/reports/restaurant",
        component: <Restaurants />,
        // children: [],
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.PUSH_NOTIFICATION],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Marketing",
    layout: "/admin",
    icon: <PiSpeakerHighFill className="h-4 w-4" />,
    path: "/marketing",
    // component: <Operations />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.PUSH_NOTIFICATION],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Push Notifications",
        layout: "/admin",
        icon: <IoNotificationsSharp className="h-4 w-4" />,
        path: "/push-notifications",
        component: <PushNotifications />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.PUSH_NOTIFICATION],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Push Notifications",
            layout: "/admin",
            path: "/push-notifications/create",
            component: <CreatePushNotification />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.REPORTING_AND_ANALYTICS],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "WhatsApp Notification",
        layout: "/admin",
        icon: <RiWhatsappFill className="h-3 w-3" />,
        path: "/whatsAppNotifications",
        component: <WhatsappNotificationForm />,
        // children: [],
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Membership Claims",
    layout: "/admin",
    icon: <GiCardExchange className="h-4 w-4" />,
    path: "/membership-claims",
    component: <MembershipClaim />,
  },
  {
    isShowInSideNav: true,
    requiredRoles: [
      PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT,
      PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT,
    ],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Systems",
    layout: "/admin",
    icon: <FaBorderAll className="h-4 w-4" />,
    path: "/customer-classifications",
    component: <Countries />,
    subMenu: [
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Configuration",
        layout: "/admin",
        icon: <IoSettingsSharp className="h-3 w-3" />,
        path: "/configuration",
        component: <ConfigurationForm />,
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Classifications",
        layout: "/admin",
        icon: <MdCategory className="h-3 w-3" />,
        path: "/customer-classifications",
        component: <CustomerClassification />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Classification",
            layout: "/admin",
            path: "/customer-classifications/create",
            component: <CreateClassification />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Classification",
            layout: "/admin",
            path: "/customer-classifications/update/:classificationId",
            component: <UpdateClassification />,
          },
        ],
      },
      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Currencies",
        layout: "/admin",
        icon: <FaMoneyBillWave className="h-3 w-3" />,
        path: "/currencies",
        component: <Currencies />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Currency",
            layout: "/admin",
            path: "/currency/create",
            component: <CreateCurrency />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Currency",
            layout: "/admin",
            path: "/currency/update/:currencyId",
            component: <UpdateCurrency />,
          },
        ],
      },

      {
        isShowInSideNav: true,
        requiredRoles: [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
        name: "Crypto Currencies",
        layout: "/admin",
        icon: <SiBitcoinsv className="h-3 w-3" />,
        path: "/crypto-currencies",
        component: <CryptoCurrencies />,
        children: [
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
            name: "Create Currency",
            layout: "/admin",
            path: "/crypto-currency/create",
            component: <CreateCryptoCurrency />,
          },
          {
            isShowInSideNav: false,
            requiredRoles: [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
            requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
            name: "Update Currency",
            layout: "/admin",
            path: "/crypto-currency/update/:currencyId",
            component: <UpdateCryptoCurrency />,
          },
        ],
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.CUSTOMER_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Customer Reviews",
    layout: "/admin",
    icon: <GiRoundStar className="h-4 w-4" />,
    path: "/rating",
    component: <Ratings />,
    children: [
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.CUSTOMER_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
        name: "Update Review",
        layout: "/admin",
        path: "/rating/update/:ratingId",
        component: <UpdateRating />,
      },
    ],
  },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.CART_RULES_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Earning Rules",
    layout: "/admin",
    icon: <TbShoppingCartDollar className="h-4 w-4" />,
    path: "/cartRules",
    component: <CartRules />,
    children: [
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.CART_RULES_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
        name: "Create Earning Rule",
        layout: "/admin",
        path: "/cartRules/create",
        component: <CreateCartRule />,
      },
      {
        isShowInSideNav: false,
        requiredRoles: [PERSMISSIONS_TYPES.CART_RULES_MANAGEMENT],
        requiredAction: PERMISSION_ACTION_TYPES.EDITOR_ACCESS,
        name: "Update Earning Rule",
        layout: "/admin",
        path: "/cartRules/update/:ruleId",
        component: <UpdateCartRule />,
      },
    ],
  },
  // {
  //   isShowInSideNav: true,
  //   requiredRoles: [PERSMISSIONS_TYPES.PUSH_NOTIFICATION],
  //   requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
  //   name: "Push Notifications",
  //   layout: "/admin",
  //   icon: <IoNotificationsSharp className="h-4 w-4" />,
  //   path: "/push-notifications",
  //   component: <PushNotifications />,
  //   children: [
  //     {
  //       isShowInSideNav: false,
  //       requiredRoles: [PERSMISSIONS_TYPES.PUSH_NOTIFICATION],
  //       requiredAction: PERMISSION_ACTION_TYPES.FULL_ACCESS,
  //       name: "Create Push Notifications",
  //       layout: "/admin",
  //       path: "/push-notifications/create",
  //       component: <CreatePushNotification />,
  //     },
  //   ],
  // },
  {
    isShowInSideNav: true,
    requiredRoles: [PERSMISSIONS_TYPES.SETTINGS_MANAGEMENT],
    requiredAction: PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS,
    name: "Settings",
    layout: "/admin",
    icon: <TbSettingsCog className="h-4 w-4" />,
    path: "/settings",
    component: <Settings />,
    children: [],
  },
];
export default routes;
