import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

import { alertReducer } from "../reducers/alert/alert.reducer";
import { authReducer } from "../reducers/auth/auth.reducer";
import { customersReducer } from "../reducers/customers/customers.reducer";
import { merchantsReducer } from "../reducers/merchants/merchants.reducer";
import { TransactionReducer } from "../reducers/transaction/TransactionReducer";
import { userReducer } from "../reducers/users/users.reducer";
import { regionReducer } from "../reducers/regions/regions.reducer";
import { languagesReducer } from "../reducers/languages/languages.reducer";
import { localizationsReducer } from "../reducers/localizations/localizations.reducer";
import { slidersReducer } from "../reducers/sliders/sliders.reducer";
import { faqsReducer } from "../reducers/faq/faq.reducer";
import { categoriesReducer } from "../reducers/categories/categories.reducer";
import { ticketsReducer } from "../reducers/tickets/ticketsReducer";
import { customerClassificationReducer } from "../reducers/customerClassification/customerClassification.reducer";
import { storeLoyaltiesReducer } from "../reducers/storeLoyalties/storeLoyalties.reducer";
import { membershipClaimsReducer } from "../reducers/membershipClaims/claims.reducer";
import { merchantPromotionTagsReducer } from "../reducers/merchantPromotionTags/merchantPromotionTags.reducer";
import { currencyReducer } from "../reducers/currency/CurrencyReducer";
import { dashboardReducer } from "../reducers/dashboard/dashboard.reducer";
import { rolesReducer } from "../reducers/roles/roles.reducer";
import { cryptoCurrencyReducer } from "../reducers/cryptoCurrency/CryptoCurrencyReducer";
import { brandsReducer } from "../reducers/Brands/brands.reducer";
import { configurationReducer } from "../reducers/configuration/configurationReducer";
import { facilityReducer } from "../reducers/facility/facility.reducer";
import { ordersReducer } from "../reducers/orders/orders.reducer";
import { RatingsReducer } from "../reducers/rating/RatingReducer";
import { cartRuleReducer } from "../reducers/cartRule/cartRule.reducer";
import { pushNotificationReducer } from "../reducers/pushNotification/pushNotification.reducer";
import { settingsReducer } from "../reducers/settings/settings.reducer";
import { VibeCategoryReducer } from "../reducers/vibeCategory/vibeCategory.reducer";
import { devicesReducer } from "../reducers/devices/devices.reducer";
import { eventsReducer } from "../reducers/events/events.reducer";
import { logsReducer } from "../reducers/activityLog/logs.reducer";
import { storeCategoriesReducer } from "../reducers/storeCategories/storeCategories.reducer";
import { storeReducer } from "../reducers/store/store.reducer";
import { storeTagsReducer } from "../reducers/storeTags/storeTags.reducer";
import { affiliateOrdersReducer } from "../reducers/affiliaiteOrders/affiliateOrders.reducer";
import { topUpsReducer } from "../reducers/topUps/topUps.reducer";
import { reportsReducer } from "../reducers/reports/reports.reducer";

export const reducers = combineReducers({
  alerts: alertReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  merchant: merchantsReducer,
  vibeCategory: VibeCategoryReducer,
  brand: brandsReducer,
  merchantPromotionTag: merchantPromotionTagsReducer,
  storeLoyalty: storeLoyaltiesReducer,
  customer: customersReducer,
  order: ordersReducer,
  transaction: TransactionReducer,
  user: userReducer,
  role: rolesReducer,
  region: regionReducer,
  language: languagesReducer,
  localization: localizationsReducer,
  slider: slidersReducer,
  faq: faqsReducer,
  category: categoriesReducer,
  ticket: ticketsReducer,
  customerClassification: customerClassificationReducer,
  claims: membershipClaimsReducer,
  currencies: currencyReducer,
  crypto: cryptoCurrencyReducer,
  configuration: configurationReducer,
  facility: facilityReducer,
  rating: RatingsReducer,
  rule: cartRuleReducer,
  pushNotification: pushNotificationReducer,
  setting: settingsReducer,
  device: devicesReducer,
  events: eventsReducer,
  logs: logsReducer,
  store: storeReducer,
  storeCategory: storeCategoriesReducer,
  storeTags: storeTagsReducer,
  affiliateOrders: affiliateOrdersReducer,
  topUps: topUpsReducer,
  reports: reportsReducer,
});

export const store = configureStore({
  reducer: reducers,
});
