import { setAlert } from "actions/alert/alert.actions";
import { getLanguages } from "actions/languages/languages.actions";
import { getStoreCompaigns } from "actions/store/store.actions";
import { getStoreCategories } from "actions/storeCategories/storeCategories.actions";
import { getStoreTags } from "actions/storeTags/storeTags.actions";
import { MODELS } from "constants/activityLog.constants";
import { FORM_TYPE } from "constants/app.constants";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AlertTypes } from "reducers/alert/alert.reducer";
import convertToFormData from "utils/ConvertToFormData";
import ActivitylogsModal from "../components/activitylogsModal";
import CardHeader from "../components/cardHeader";
import ConfirmationModal from "../components/ConfirmationModal";
import DescriptionEditor from "../components/descriptionEditor";
import FormFileUploader from "../components/fileUploader";
import FormSectionHeader from "../components/formSectionHeader";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import MultiSelect from "../components/multiSelect";
import TabSelector from "../components/TabSelector";

const initialMultiLangData = {
  name: "",
  exclusion: "",
  special_terms: "",
  deals_and_coupons: "",
};

const initialStoreData = {
  ...initialMultiLangData,
  logo: "",
  banner: "",
  url: "",
  currency: "",
  actions: [],
  actions_detail: [],
  categories: [],
  tags: [],
  display_order: 1,
  save_time: "",
  program_id: "",
};

const StoreForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redux-state
  const { loading, storesCount, storeMultiLang, campaigns } = useSelector(
    (state) => state.store
  );
  const { languages } = useSelector((state) => state.language);
  const { categories } = useSelector((state) => state.storeCategory);
  const { tags } = useSelector((state) => state.storeTags);

  // local-state
  const [storeData, setStoreData] = useState({
    ...initialStoreData,
    display_order: storesCount + 1,
  });
  console.log({ storeData })
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    const { ...remainingData } = initialFormData;
    setStoreData((prevData) => ({
      ...prevData,
      ...remainingData,
    }));
  }, [initialFormData]);

  useEffect(() => {
    if (!storeData.program_id || !campaigns?.length) return;
    const program = campaigns.find(
      (campaign) => campaign.id.toString() === storeData.program_id
    );
    const actionsDetail = program.actions_detail.map((action_detail) => ({
      ...action_detail,
      tariffs: action_detail.tariffs.map(tariff => ({
        ...tariff,
        rates: tariff.rates.map(rate => ({
          ...rate, app_cashback: Number(rate.size)
        })),
      }))
    }));
    setStoreData((prev) => ({
      ...prev,
      currency: program.currency,
      actions: program.actions,
      actions_detail: actionsDetail,
    }));
  }, [storeData.program_id]);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
    dispatch(getStoreCategories(1, 1000));
    dispatch(getStoreTags(1, 1000));
    dispatch(getStoreCompaigns());
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = storeMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  // submit-handler
  const handleSubmit = (redirect) => {
    const errors = [];
    Object.entries(storeData).forEach(([key, value]) => {
      if (!value) errors.push(`${key} is required`);
      if (Array.isArray(value) && value.length == 0)
        errors.push(`${key} are required. select atleast one.`);
    });
    storeData.actions_detail.forEach(action_detail => {
      action_detail.tariffs.forEach(tariff => {
        tariff.rates.forEach(rate => {
          if (rate.app_cashback > rate.size) errors.push(`Rates > ${action_detail.name} > ${tariff.name} > app cashback > value should not be greater than store cashback`);
        })
      })
    })

    if (errors.length) {
      errors.map((error) => dispatch(setAlert(error, AlertTypes.ERROR)));
      return;
    }

    let payload = { ...storeData };
    if (payload.id) delete payload.id;
    Object.keys(payload).forEach((key) => {
      if (initialStoreData[key] == undefined) {
        delete payload[key];
      }
    });
    if (lang_id) payload = { ...multiLangData, lang_id };
    const fromData = convertToFormData(payload);
    handleFormSubmit(fromData, redirect);
  };

  const onChangeFormData = ({ name, value }) => {
    if (lang_id) {
      setMultiLangData((prevState) => ({ ...prevState, [name]: value }));
      return;
    }
    setStoreData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRateChange = (action_id, tariff_id, rate_id, app_cashback) => {
    const actionsDetail = storeData.actions_detail.map((action_detail) => {
      if (action_detail.id != action_id) return action_detail
      return {
        ...action_detail,
        tariffs: action_detail.tariffs.map(tariff => {
          if (tariff.id != tariff_id) return tariff
          return {
            ...tariff,
            rates: tariff.rates.map(rate => {
              if (rate.id !== rate_id) return rate
              return {
                ...rate,
                app_cashback,
              }
            }),
          }
        })
      }
    });
    setStoreData((prev) => ({
      ...prev,
      actions_detail: actionsDetail,
    }));
  };

  return (
    <div className="h-full w-full bg-white">
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.STORE}
          entityId={storeData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/stores")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to stores
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Store`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/stores"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="false flex-col gap-3 p-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}

        <div className={`mt-2 flex flex-col justify-between gap-2`}>
          {!lang_id ? (
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Logo
                </label>
                <FormFileUploader
                  image={storeData.logo}
                  handleUpload={(acceptedFiles) =>
                    onChangeFormData({ name: "logo", value: acceptedFiles[0] })
                  }
                />
              </div>
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Banner
                </label>
                <FormFileUploader
                  image={storeData.banner}
                  handleUpload={(acceptedFiles) =>
                    onChangeFormData({
                      name: "banner",
                      value: acceptedFiles[0],
                    })
                  }
                />
              </div>
            </div>
          ) : null}
          {/* name and displayOrder */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            {/* Name */}
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Name
              </label>
              <input
                onChange={(e) => onChangeFormData(e.target)}
                type="text"
                name="name"
                value={lang_id ? multiLangData.name : storeData.name}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
            {/* display order  */}
            {!lang_id ? (
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Display Order
                </label>
                <input
                  onChange={(e) => onChangeFormData(e.target)}
                  type="number"
                  name="display_order"
                  value={storeData.display_order}
                  className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                />
              </div>
            ) : null}
          </div>
          {/* URL  */}
          {!lang_id ? (
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                IFrame URL
              </label>
              <input
                onChange={(e) => onChangeFormData(e.target)}
                type="text"
                name="url"
                value={storeData.url}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
          ) : null}

          {/* program */}
          {!lang_id ? (
            <div className="w-full">
              <HeadlessSelectObject
                label="Select admitad Program"
                disable={
                  formType == FORM_TYPE.UPDATE || !campaigns?.length || loading
                }
                title={campaigns?.length ? "select brand" : "No brand found"}
                options={[
                  ...campaigns?.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={storeData.program_id}
                onSelect={(value) =>
                  onChangeFormData({
                    name: "program_id",
                    value: value.toString(),
                  })
                }
              />
            </div>
          ) : null}

          {/* categories */}
          {!lang_id ? (
            <div className="w-full gap-2">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Categories
              </label>
              <MultiSelect
                options={categories?.map((t) => ({
                  label: t.name,
                  value: t.id,
                }))}
                selectedValues={storeData.categories}
                setSelectedValues={(values) =>
                  onChangeFormData({
                    name: "categories",
                    value: values,
                  })
                }
              />
            </div>
          ) : null}

          {/* tags */}
          {!lang_id ? (
            <div className="w-full gap-2">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Tags
              </label>
              <MultiSelect
                options={tags.map((t) => ({
                  label: t.name,
                  value: t.id,
                }))}
                selectedValues={storeData.tags}
                setSelectedValues={(values) =>
                  onChangeFormData({
                    name: "tags",
                    value: values,
                  })
                }
              />
            </div>
          ) : null}

          {/* exclusion */}
          <div className="w-full">
            <label
              for="content"
              className="mb-1 block text-base font-medium text-gray-800"
            >
              Exclusions
            </label>
            <DescriptionEditor
              value={lang_id ? multiLangData.exclusion : storeData.exclusion}
              onChange={(value) =>
                onChangeFormData({
                  name: "exclusion",
                  value: value,
                })
              }
            />
          </div>

          {/* specialTerms */}
          <div className="w-full">
            <label
              for="content"
              className="mb-1 block text-base font-medium text-gray-800"
            >
              Special Terms
            </label>
            <DescriptionEditor
              value={
                lang_id ? multiLangData.special_terms : storeData.special_terms
              }
              onChange={(value) =>
                onChangeFormData({
                  name: "special_terms",
                  value: value,
                })
              }
            />
          </div>

          {/* deals & cupons  */}
          <div className="w-full">
            <label
              for="content"
              className="mb-1 block text-base font-medium text-gray-800"
            >
              Deals & Cupons
            </label>
            <DescriptionEditor
              value={
                lang_id
                  ? multiLangData.deals_and_coupons
                  : storeData.deals_and_coupons
              }
              onChange={(value) =>
                onChangeFormData({
                  name: "deals_and_coupons",
                  value: value,
                })
              }
            />
          </div>

          {/* save time  */}
          {!lang_id ? (
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Save time in hours
              </label>
              <input
                onChange={(e) => onChangeFormData(e.target)}
                type="number"
                name="save_time"
                value={storeData.save_time}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
          ) : null}

          {/* rates */}
          {!lang_id ? (
            <>
              {storeData.actions_detail.map((action_detail) => (
                <div className="mt-3" key={action_detail.id}>
                  <FormSectionHeader text={action_detail.name} />
                  {action_detail.tariffs.map((tariff) => (
                    <Fragment key={tariff.id}>
                      <div className="flex items-center justify-between gap-2 font-semibold text-gray-500">
                        <div className="w-full sm:w-1/3">{tariff.name}</div>
                      </div>
                      {tariff.rates.map((rate) => (
                        <div
                          key={rate.id}
                          className="flex flex-col items-center justify-between gap-2 md:flex-row md:gap-6"
                        >
                          <div className="flex w-full items-center justify-between gap-2 md:w-1/3">
                            <label className="flex flex-shrink-0 whitespace-nowrap text-base font-medium text-gray-800">
                              Store Cashback:
                            </label>
                            <input
                              type="number"
                              disabled={true}
                              name="size"
                              placeholder="Enter cashback value"
                              value={rate.size}
                              onChange={(e) => null}
                              className={`flex min-w-4 max-w-full flex-auto  rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none ${
                                formType == FORM_TYPE.UPDATE
                                  ? "bg-gray-100"
                                  : ""
                              }`}
                            />
                          </div>
                          <div className="flex w-full items-center justify-between gap-2 md:w-1/3">
                            <label className="flex flex-shrink-0 items-center whitespace-nowrap text-base font-medium text-gray-800">
                              Mithu Cashback:{" "}
                              <span className="ml-1 text-sm text-gray-600">
                                (Max: {rate.size})
                              </span>
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={Number(rate.size)}
                              name="app_cashback"
                              placeholder="Enter Cashback"
                              value={rate.app_cashback}
                              onChange={(e) => {
                                if (
                                  isNaN(Number(e.target.value)) ||
                                  Number(e.target.value) > Number(rate.size)
                                )
                                  return;
                                handleRateChange(
                                  action_detail.id,
                                  tariff.id,
                                  rate.id,
                                  Number(e.target.value)
                                );
                              }}
                              className="flex min-w-4 max-w-full flex-auto rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                            />
                          </div>
                          <div className="flex w-full items-center justify-between gap-2 md:w-1/3">
                            <label className="flex flex-shrink-0 whitespace-nowrap text-base font-medium text-gray-800">
                              Value Type:
                            </label>
                            <input
                              type="text"
                              name="type"
                              disabled={true}
                              placeholder="Rate Type"
                              value={
                                rate.is_percentage
                                  ? "Percent"
                                  : storeData.currency
                              }
                              onChange={(e) => null}
                              className={`flex min-w-4 max-w-full flex-auto rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none ${
                                formType == FORM_TYPE.UPDATE
                                  ? "bg-gray-100"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      ))}
                    </Fragment>
                  ))}
                </div>
              ))}
            </>
          ) : null}
        </div>

        {/* buttons */}
        <div className="mt-3 flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${
            loading
              ? "bg-indigo-400 hover:bg-indigo-400"
              : "bg-indigo-500 hover:bg-indigo-600"
          }
          `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreForm;
