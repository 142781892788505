import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";
import { exportTransactions } from "actions/transactions/TransactionActions";
import { PiExport } from "react-icons/pi";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import RestaurantsTableCard from "./restaurants-table-card";
import { GetAllRestaurantReports } from "actions/reports/ReportsActions";

const Restaurants = () => {
  const dispatch = useDispatch();
  const { reportsCount, reports, loading } = useSelector(
    (state) => state.reports
  );
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [fromDate, setFromDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const getFilteredTransactions = () => {
    setFiltersApplied(true);
    dispatch(GetAllRestaurantReports(fromDate));
  };

  useEffect(() => {
    const prev_filter_state = filtersApplied;
    getFilteredTransactions();
    setFiltersApplied(prev_filter_state);
  }, [page]);

  const removeAllFilters = () => {
    setFiltersApplied(false);
    setFromDate(dayjs(new Date()).format("YYYY-MM-DD"));
    dispatch(GetAllRestaurantReports(fromDate));
  };

  // const exportTransaction = (appFilters) => {
  //   if (!appFilters) dispatch(exportTransactions());
  //   else {
  //     dispatch(exportTransactions(fromDate));
  //   }
  // };

  return (
    <div className="h-full w-full">
      <RestaurantsTableCard reports={reports}>
        <div className="flex items-end justify-between">
          {/* export buttons */}
          <div className="flex items-center justify-start gap-2 p-2">
            {/* {hasPermission(
              [PERSMISSIONS_TYPES.REPORTING_AND_ANALYTICS],
              PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS
            ) ? (
              <div className="w-auto">
                <button
                  disabled={loading}
                  onClick={() => exportTransaction(false)}
                  className={`flex items-center gap-1 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
                  type="button"
                >
                  <PiExport className="h-4 w-4" />
                  {"Export All"}
                </button>
              </div>
            ) : null}

            {filtersApplied ? (
              <div className="w-auto">
                <button
                  disabled={loading}
                  onClick={() => exportTransaction(true)}
                  className={`flex items-center gap-1 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
                  type="button"
                >
                  <PiExport className="h-4 w-4" />
                  {"Export Filtered Data"}
                </button>
              </div>
            ) : null} */}
          </div>

          {/* filter buttons */}
          <div className="flex items-center justify-end gap-2 p-2">
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={removeAllFilters}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
                type="button"
              >
                <MdFilterAltOff className="h-4 w-4" />
                {"Clear Filters"}
              </button>
            </div>
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={getFilteredTransactions}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
                type="button"
              >
                <CiFilter className="h-4 w-4" />
                {"Apply Filters"}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="flex w-full items-center justify-center gap-2 rounded-md border border-gray-300 p-1 md:w-1/3 lg:w-1/5">
              <input
                className="text-sm"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </RestaurantsTableCard>
      {/* <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={reportsCount}
      /> */}
    </div>
  );
};

export default Restaurants;
