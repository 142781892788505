
import { getCountries } from "actions/regions/regions.actions";
import { getStates } from "actions/regions/regions.actions";
import { setCities } from "actions/regions/regions.actions";
import { setStates } from "actions/regions/regions.actions";
import { getCities } from "actions/regions/regions.actions";
import { getAreas } from "actions/regions/regions.actions";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { getSystemSettings } from "selectors/settings/settings.selector";
import HeadlessSelectObject from "views/admin/components/headless/selectObject.headless";

const initialAreaFilters = {
  country: "",
  state: "",
  city: "",
}

const AreasFilters = ({ page }) => {
  const dispatch = useDispatch()
  const { countries, states, cities, loading } = useSelector(state => state.region)
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const [filters, setFilters] = useState(initialAreaFilters)
  const optionAll = { name: "All", value: "" };

  useEffect(() => {
    dispatch(getCountries())
  }, [])

  useEffect(() => {
    setFilters((prevData) => ({ ...prevData, state: "", city: "" }));
    dispatch(setStates({ states: [] }));
    dispatch(setCities({ cities: [] }));
    if (!filters.country) return;
    const temp_country = countries.find((c) => c.id === filters.country);
    dispatch(getStates(temp_country.code));
  }, [filters.country]);

  useEffect(() => {
    setFilters((prevData) => ({ ...prevData, city: "" }));
    dispatch(setCities({ cities: [] }));
    if (!filters.country || !filters.state) return;
    const temp_country = countries.find((c) => c.id === filters.country);
    const temp_state = states.find((s) => s.id === filters.state);
    dispatch(getCities(temp_country.code, temp_state.code));
  }, [filters.state]);

  const getFilteredAreas = (page) => {
    const { country, state, city } = filters
    dispatch(getAreas(page, limit, country, state, city))
  }

  useEffect(() => {
    getFilteredAreas(page)
  }, [page]);

  const removeAllFilters = () => {
    setFilters(initialAreaFilters)
    dispatch(getAreas(1, limit))
  }

  const handleOnChange = (name, value) => {
    setFilters((prevData) => ({ ...prevData, [name]: value }))
  }

  return (
    <div className="w-full h-auto">

      {/* filter buttons */}
      <div className="flex items-center justify-end gap-2 p-2">
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={removeAllFilters}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
            type="button"
          >
            <MdFilterAltOff className="h-4 w-4" />
            {"Clear Filters"}
          </button>
        </div>
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={() => getFilteredAreas(1)}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
            type="button"
          >
            <CiFilter className="h-4 w-4" />
            {"Apply Filters"}
          </button>
        </div>
      </div>

      <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
        {/* Country, State and City */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="w-full  md:w-1/3">
            <HeadlessSelectObject
              disable={!countries.length || loading}
              title={loading ? "Loading..." : !countries.length ? "No Country" : "All Countries"}
              options={[
                optionAll,
                ...countries.map((t) => ({ name: t.name, value: t.id })),
              ]}
              selected={filters.country}
              onSelect={(value) => handleOnChange("country", value)}
            />
          </div>

          <div className="w-full md:w-1/3">
            <HeadlessSelectObject
              disable={!states.length || loading}
              title={loading ? "Loading..." : !states.length ? "No State" : "All States"}
              options={[
                optionAll,
                ...states.map((t) => ({ name: t.name, value: t.id })),
              ]}
              selected={filters.state}
              onSelect={(value) => handleOnChange("state", value)}
            />
          </div>

          <div className="w-full md:w-1/3">
            <HeadlessSelectObject
              disable={!cities.length || loading}
              title={loading ? "Loading..." : !cities.length ? "No City " : "All Cities"}
              options={[
                optionAll,
                ...cities.map((t) => ({ name: t.name, value: t.id })),
              ]}
              selected={filters.city}
              onSelect={(value) => handleOnChange("city", value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreasFilters;