import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import WhatsappNotification from "../../services/whatsappNotification/WhatsappNotification";

export const sendNotification = (formData) => async (dispatch) => {
  try {
    const response = await WhatsappNotification.sendNotification(formData);
    if (response.data && response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Whatsapp Notifications Sent Successfully!",
          AlertTypes.SUCCESS
        )
      );
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to send Whatsapp Notifications!",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  }
  // finally {
  //   dispatch(setLoading(false));
  // }
};
