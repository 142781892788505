import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import convertToFormData from "utils/ConvertToFormData";
import CardHeader from "../components/cardHeader";
import FormFileUploaderCSV from "../components/CSVfileUploader";
import { sendNotification } from "actions/whatsappNotifications/whatsappNotification.action";

const initialNotificationData = {
  template_id: "",
  csv: "",
};

const WhatsappNotificationForm = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.merchant);
  const [notificationData, setNotificationData] = useState({
    ...initialNotificationData,
  });
  // console.log({ notificationData });
  const onChangeFormData = ({ name, value }) => {
    setNotificationData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleUpload = (file) => {
    // console.log(file);
    setNotificationData((prevState) => ({
      ...prevState,
      csv: file,
    }));
  };

  const handleSubmit = (redirect) => {
    let payload = { ...notificationData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialNotificationData[key] == undefined) {
        delete payload[key];
      }
    });

    let formData = convertToFormData(payload);
    // handleFormSubmit(formData, redirect);
    dispatch(sendNotification(formData));
  };

  return (
    <div className="h-full w-full bg-white">
      <CardHeader
        label={`Whatsapp Notification`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate(-1),
            variant: "dark",
          },
          {
            text: "Send",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        {/* cvc selector  */}
        <div className="flex h-full w-full flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Upload CSV
          </label>
          {/* <FormFileUploaderCSV
            handleUpload={(acceptedFiles) => {
              console.log({ acceptedFiles });
              onChangeFormData({ name: "csv", value: acceptedFiles[0] });
            }}
          /> */}
          <FormFileUploaderCSV handleUpload={handleUpload} />
        </div>
        {/* templae id message */}
        <div className="flex flex-auto flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Message Tempelate ID
          </label>
          <input
            type="text"
            name="id"
            value={notificationData.template_id}
            onChange={(e) =>
              onChangeFormData({
                name: "template_id",
                value: e.target.value,
              })
            }
            className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
          />
        </div>
        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Sending..." : "Send"}
          </button>
          {/* <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default WhatsappNotificationForm;
