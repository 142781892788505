import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const sendNotification = (payload) => {
  return RestClient.Post(API_URL.SEND_WHATSAPP_NOTIFICATIONS, payload);
};

const exportedObject = {
  sendNotification,
};

export default exportedObject;
