const API_URL = {
  // Auth
  // USER_REGISTER: "v1/auth/interaction/register",
  USER_SIGNIN: "v1/auth/interaction/login",
  USER_VALIDATE: "v1/auth/interaction/validate",
  USER_LOGOUT: "v1/auth/interaction/logout",
  REQUEST_FORGOT_PASSWORD: "v1/auth/user/request-forgot-password",
  FORGOT_PASSWORD_OTP_VALIDATE: "v1/auth/user/forgot-password-otp-confirmation",
  RESET_PASSWORD: "v1/auth/user/reset-password",

  // Customer
  GET_CUSTOMERS: "v1/customer/get-all",
  EXPORT_CUSTOMERS: "v1/customer/export",
  UPDATE_CUSTOMER: "v1/customer/profile",
  GET_CUSTOMER_PROFILE: "v1/customer/get-profile",

  // Orders
  GET_ORDERS: "v1/order",
  EXPORT_ORDERS: "v1/order/export",

  // Affiliate Orders
  GET_AFFILIATE_ORDERS: "v1/affiliate-market/order",
  EXPORT_AFFILIATE_ORDERS: "v1/affiliateOrders/export",

  // TopUps
  GET_TOPUPS: "v1/topup/get-all",
  EXPORT_TOPUPS: "v1/topups/export",

  // Language
  GET_LANGUAGES: "v1/language/get-all",
  GET_LANGUAGE: "v1/language",
  CREATE_LANGUAGE: "v1/language/create",
  UPDATE_LANGUAGE: "v1/language",
  UPDATE_MULTI_LANG_LANGUAGE: "/v1/language/update-multi-lang",
  DELETE_LANGUAGE: "v1/language",

  // Localization
  GET_LOCALIZATIONS: "v1/localization/get-all",
  CREATE_LOCALIZATION: "v1/localization/create",
  UPDATE_LOCALIZATION: "v1/localization",
  DELETE_LOCALIZATION: "v1/localization",

  // Merchant
  GET_MERCHANTS: "v1/merchant/get-all",
  GET_MERCHANT: "v1/merchant",
  CREATE_MERCHANT: "v1/merchant/create",
  UPDATE_MERCHANT: "v1/merchant",
  UPDATE_MERCHANT_PASSWORD: "v1/merchant/update-password",
  UPDATE_LANGUAGE_MERCHANT: "v1/merchant/update-multi-lang",
  PUBLISH_MERCHANT: "v1/merchant/publish",
  DELETE_MERCHANT: "v1/merchant",

  // Brands
  GET_BRANDS: "v1/brand/get-all",
  GET_BRAND: "v1/brand",
  CREATE_BRAND: "v1/brand/create",
  UPDATE_BRAND: "v1/brand",
  UPDATE_BRAND_PASSWORD: "v1/brand/update-password",
  // UPDATE_LANGUAGE_BRAND: "v1/brand/update-multi-lang",
  DELETE_BRAND: "v1/brand",

  // Facility
  GET_FACILITIES: "v1/facility/get-all",
  GET_FACILITY: "v1/facility",
  CREATE_FACILITY: "v1/facility/create",
  UPDATE_FACILITY: "v1/facility",
  UPDATE_LANGUAGE_FACILITY: "v1/facility/update-multi-lang",
  DELETE_FACILITY: "v1/facility",

  // Merchant Tag
  GET_MERCHANT_TAGS: "v1/merchant/tag/get-all",
  GET_MERCHANT_TAG: "v1/merchant/tag",
  CREATE_MERCHANT_TAG: "v1/merchant/tag/create",
  UPDATE_MERCHANT_TAG: "v1/merchant/tag",
  UPDATE_LANGUAGE_MERCHANT_TAG: "v1/merchant/tag/update-multi-lang",
  DELETE_MERCHANT_TAG: "v1/merchant/tag",

  // Merchant Type
  GET_MERCHANT_TYPES: "v1/merchant/type/get-all",
  GET_MERCHANT_TYPE: "v1/merchant/type",
  CREATE_MERCHANT_TYPE: "v1/merchant/type/create",
  UPDATE_MERCHANT_TYPE: "v1/merchant/type",
  UPDATE_LANGUAGE_MERCHANT_TYPE: "v1/merchant/type/update-multi-lang",
  DELETE_MERCHANT_TYPE: "v1/merchant/type",

  // Merchant Promotion Tags
  GET_MERCHANT_PROMOTION_TAGS: "/v1/merchant/promotion/get-all",
  GET_MERCHANT_PROMOTION_TAG: "/v1/merchant/promotion",
  CREATE_MERCHANT_PROMOTION_TAG: "/v1/merchant/promotion/create",
  UPDATE_MERCHANT_PROMOTION_TAG: "/v1/merchant/promotion",
  UPDATE_MULTI_LANG_MERCHANT_PROMOTION_TAG:
    "/v1/merchant/promotion/update-multi-lang",
  DELETE_MERCHANT_PROMOTION_TAG: "/v1/merchant/promotion",

  // Merchant Vibe
  GET_VIBES: "v1/merchant/vibe/get-all",
  GET_VIBE: "v1/merchant/vibe",
  GET_VIBE_LIKED_BY: "v1/merchant/vibe/likedBy",
  GET_VIBE_SEEN_BY: "v1/merchant/vibe/seenBy",
  CREATE_VIBE: "v1/merchant/vibe/create",
  UPDATE_VIBE: "v1/merchant/vibe",
  DELETE_VIBE: "v1/merchant/vibe",

  // Vibe Categoriess
  GET_MERCHANT_VIBE_CATEGORIES: "v1/merchant/vibe-category/get-all",

  // Region
  GET_COUNTRIES: "v1/region/country/get-all",
  GET_STATES: "v1/region/state/get-all",
  GET_CITIES: "v1/region/city/get-all",
  GET_AREAS: "v1/region/area/get-all",
  GET_AREA: "v1/region/area",
  CREATE_AREA: "v1/region/area/create",
  UPDATE_AREA: "v1/region/area",
  DELETE_AREA: "v1/region/area",

  // User
  GET_USERS: "v1/user/get-all",
  GET_USER: "v1/user",
  CREATE_USER: "/v1/user/create",
  UPDATE_USER: "v1/user",
  UPDATE_USER_PASSWORD: "v1/user/update-password",
  DELETE_USER: "v1/user",

  // roles
  GET_ROLES: "v1/role/get-all",
  GET_ROLE_PERMISSIONS: "/v1/role/permissions",
  UPDATE_ROLE: "v1/role",

  // Transaction
  GET_TRANSACTIONS: "v1/transaction/get-all",
  EXPORT_TRANSACTIONS: "v1/transaction/export",

  // Sliders
  GET_SLIDERS: "/v1/slider/get-all",
  GET_SLIDER: "/v1/slider",
  CREATE_SLIDER: "/v1/slider/create",
  UPDATE_SLIDER: "/v1/slider",
  UPDATE_MULTI_LANG_SLIDER: "/v1/slider/update-multi-lang",
  DELETE_SLIDER: "/v1/slider",

  // FAQs
  GET_FAQS: "/v1/faq/get-all",
  GET_FAQ: "/v1/faq",
  CREATE_FAQ: "/v1/faq/create",
  UPDATE_FAQ: "/v1/faq",
  UPDATE_MULTI_LANG_FAQ: "/v1/faq/update-multi-lang",
  DELETE_FAQ: "/v1/faq",

  // Category
  GET_CATEGORIES: "/v1/category/get-all",
  GET_CATEGORY: "/v1/category",
  CREATE_CATEGORY: "/v1/category/create",
  UPDATE_CATEGORY: "/v1/category",
  UPDATE_MULTI_LANG_CATEGORY: "/v1/category/update-multi-lang",
  DELETE_CATEGORY: "/v1/category",

  // Tickets
  GET_TICKETS: "/v1/ticket/get-all",
  GET_TICKET_TYPES: "/v1/ticket/types/get-all",
  GET_TICKET: "/v1/ticket",
  CREATE_TICKET: "/v1/ticket/create",
  UPDATE_TICKET: "/v1/ticket",
  // UPDATE_MULTI_LANG_TICKET: "/v1/ticket/update-multi-lang",
  DELETE_TICKET: "/v1/ticket",

  // customerClassification
  GET_CUSTOMER_CLASSIFICATIONS: "/v1/customer-classification/get-all",
  GET_CUSTOMER_CLASSIFICATION: "/v1/customer-classification",
  CREATE_CUSTOMER_CLASSIFICATION: "/v1/customer-classification",
  UPDATE_CUSTOMER_CLASSIFICATION: "/v1/customer-classification",
  UPDATE_MULTI_LANG_CUSTOMER_CLASSIFICATION:
    "/v1/customer-classification/update-multi-lang",
  DELETE_CUSTOMER_CLASSIFICATION: "/v1/customer-classification",

  // Store Loyalties
  GET_STORE_LOYALTIES: "/v1/store-loyalty/get-all",
  GET_STORE_LOYALTY: "/v1/store-loyalty",
  CREATE_STORE_LOYALTY: "/v1/store-loyalty/create",
  UPDATE_STORE_LOYALTY: "/v1/store-loyalty",
  UPDATE_MULTI_LANG_STORE_LOYALTY: "/v1/store-loyalty/update-multi-lang",
  DELETE_STORE_LOYALTY: "/v1/store-loyalty",

  // Claim Membership
  GET_CLAIMS: "/v1/membership-claim/get-all",

  // Currencies
  GET_CURRENCIES: "/v1/currency/get-all",
  GET_CURRENCY: "/v1/currency",
  CREATE_CURRENCY: "/v1/currency/create",
  UPDATE_CURRENCY: "/v1/currency",
  DELETE_CURRENCY: "/v1/currency",

  // Crypto Currencies
  GET_CRYPTO_CURRENCIES: "/v1/crypto-currency/get-all",
  GET_CRYPTO_CURRENCY: "/v1/crypto-currency",
  CREATE_CRYPTO_CURRENCY: "/v1/crypto-currency/create",
  UPDATE_CRYPTO_CURRENCY: "/v1/crypto-currency",
  DELETE_CRYPTO_CURRENCY: "/v1/crypto-currency",

  // Dashboard
  GET_DASHBOARD_STATS: "/v1/dashboard/stats",
  GET_EARNING_REDEMPTION_CHART_DATE: "/v1/dashboard/earning-redemption",
  GET_TOP_MERCHANTS: "/v1/dashboard/top-merchants",
  GET_TOP_CUSTOMERS: "/v1/dashboard/top-customers",
  GET_EARNING_REDEMPTION_STATS: "/v1/dashboard/earning-redemption-stats",
  GET_LANGUAGES_CUSTOMERS_STATS: "/v1/dashboard/languages-customers-stats",

  // Configuration
  GET_CONFIGURATION: "/v1/configuration/get",
  UPDATE_CONFIGURATION: "/v1/configuration/update",

  // Ratings
  GET_RATINGS: "/v1/rating/get-all",
  GET_RATING: "/v1/rating",
  UPDATE_RATING: "/v1/rating",

  // Cart Rules
  GET_CART_RULES: "v1/cart-rule/get-all",
  GET_CART_RULE: "v1/cart-rule",
  CREATE_CART_RULE: "v1/cart-rule/create",
  UPDATE_CART_RULE: "v1/cart-rule",
  TOGGLE_CART_RULE_PUBLISH_STATUS: "v1/cart-rule/toggle-publish",
  DELETE_CART_RULE: "v1/cart-rule",

  // Push Notification
  GET_PUSH_NOTIFICATIONS: "v1/push-notification/get-all",
  CREATE_PUSH_NOTIFICATION: "v1/push-notification/create",

  // whatsapp notifications
  SEND_WHATSAPP_NOTIFICATIONS: "v1/marketing/whatsapp",

  // Settings
  GET_SETTINGS: "/v1/setting/get-all",
  GET_SYSTEM_SETTINGS: "/v1/setting/get-all-system-settings",
  GET_SETTING: "/v1/setting",
  CREATE_SETTING: "/v1/setting/create",
  UPDATE_SETTING: "/v1/setting",
  DELETE_SETTING: "/v1/setting",

  // Brand devices
  GET_DEVICES: "v1/device/get-all",
  GET_DEVICE: "v1/device",
  CREATE_DEVICE: "v1/device/create",
  UPDATE_DEVICE: "v1/device",
  UPDATE_DEVICE_ENABLE_FLAG: "v1/device/toggle-enable-status",
  UPDATE_DEVICE_INTEGRATION_FLAG: "v1/device/toggle-integration-status",
  UPDATE_DEVICE_PIN: "v1/device/update-pin",
  DELETE_DEVICE: "v1/device",

  //activity logs
  GET_LOGS: "v1/activity-log/get-all",

  // Store
  GET_STORES: "/v1/store/get-all",
  GET_STORE: "/v1/store",
  CREATE_STORE: "/v1/store/create",
  UPDATE_STORE: "/v1/store",
  UPDATE_MULTI_LANG_STORE: "/v1/store/update-multi-lang",
  DELETE_STORE: "/v1/store",
  GET_COMPAINGNS: "/v1/store/compaigns/get-all",

  // Store Category
  GET_STORE_CATEGORIES: "/v1/store-category/get-all",
  GET_STORE_CATEGORY: "/v1/store-category",
  CREATE_STORE_CATEGORY: "/v1/store-category/create",
  UPDATE_STORE_CATEGORY: "/v1/store-category",
  UPDATE_MULTI_LANG_STORE_CATEGORY: "/v1/store-category/update-multi-lang",
  DELETE_STORE_CATEGORY: "/v1/store-category",

  // Store Tags
  GET_STORE_TAGS: "/v1/store-tag/get-all",
  GET_STORE_TAG: "/v1/store-tag",
  CREATE_STORE_TAG: "/v1/store-tag/create",
  UPDATE_STORE_TAG: "/v1/store-tag",
  UPDATE_MULTI_LANG_STORE_TAG: "/v1/store-tag/update-multi-lang",
  DELETE_STORE_TAG: "/v1/store-tag",

  // Reports
  GET_RESTAURANT_REPORTS: "/v1/report/restaurants",
  EXPORT_RESTAURANT_REPORTS: "v1/report/export",
  GET_OPERATIONS_REPORTS: "/v1/report/operations",
  EXPORT_OPERATIONS_REPORTS: "v1/operations/export",
};

export { API_URL };
