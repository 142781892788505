import { setAlert } from "actions/alert/alert.actions";
import { getLanguages } from "actions/languages/languages.actions";
import {
  getMerchantTags,
  getMerchantTypes,
} from "actions/merchants/merchants.actions";
import { CURRENCY_OPTIONS, MERCHANT_TYPE } from "constants/merchant.constants";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "reducers/alert/alert.reducer";
import convertToFormData from "utils/ConvertToFormData";
import ConfirmationModal from "../../components/ConfirmationModal";
import TabSelector from "../../components/TabSelector";
import CardHeader from "../../components/cardHeader";

import { getCategories } from "actions/categories/categories.actions";
import { FORM_TYPE } from "constants/app.constants";
import { DEFAULT_OPERATION_TIME } from "constants/merchant.constants";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { submitFormHandler } from "utils/App.utils";
import validateMerchantForm from "utils/merchants/ValidateMerchantForm";
import HeadlessSelectObject from "views/admin/components/headless/selectObject.headless";
import MultiSelect from "views/admin/components/multiSelect";
import FormSectionHeader from "../../components/formSectionHeader";
import BasicInformation from "./basicInformation";
import ContactInfo from "./contactInfo";
import DeliveryOption from "./deliveryOptions";
import EarningRedemtion from "./earning-Redemtion";
import MarketingSection from "./marketingSection";
import MerchantRegion from "./merchantRegion";
import OperationHours from "./operationHours";
import SocialLinks from "./socialLinks";
import { validatePhoneNumber } from "utils/App.utils";
import { getBrands } from "actions/Brands/brands.action";
import UpdatePassword from "views/admin/components/updatePassword";
import { updateMerchantPassword } from "actions/merchants/merchants.actions";
import { getFacilities } from "actions/facility/facility.action";
import ActivitylogsModal from "views/admin/components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

dayjs.extend(utc);
dayjs.extend(timezone);

const initialMultiLangData = {
  name: "",
  description: "",
  short_description: "",
  listing_image: "",
  cover_image: "",
  address: "",
  phone: "",
};

const initialMerchantData = {
  name: "",
  brand_id: "",
  type: MERCHANT_TYPE.DEFAULT,
  description: "",
  short_description: "",
  facilities: [],
  is_most_loved: false,
  // delivery_time: 0,
  currency: CURRENCY_OPTIONS[0],
  minimum_redeem_points: 0,
  cashback_percent: 0,
  reward_points: 0,
  logo: "",
  listing_image: "",
  cover_image: "",
  country_id: "",
  state_id: "",
  city_id: "",
  area_id: "",
  category_id: "",
  address: "",
  merchant_type_ids: [],
  merchant_tag_ids: [],
  timeZone: dayjs.tz.guess(),
  operational_hours: {
    Monday: DEFAULT_OPERATION_TIME,
    Tuesday: DEFAULT_OPERATION_TIME,
    Wednesday: DEFAULT_OPERATION_TIME,
    Thursday: DEFAULT_OPERATION_TIME,
    Friday: DEFAULT_OPERATION_TIME,
    Saturday: DEFAULT_OPERATION_TIME,
    Sunday: DEFAULT_OPERATION_TIME,
  },
  contact_info: {
    phone: "",
    public_phone: "",
    internal_phone: "",
    email: "",
    website: "",
  },
  social_profiles: {
    facebook: "",
    instagram: "",
    twitter: "",
  },
  website_url: "",
  delivery_options: {
    available: true,
    deliveryRadius: 0,
    deliveryFee: 0,
    deliveryTime: 0,
  },
  location: {
    type: "Point",
    coordinates: [0, 0], // [lng, lat]
    name: "",
  },
};

const MerchantForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, merchnatMultiLang } = useSelector((state) => state.merchant);
  const { languages } = useSelector((state) => state.language);
  const { types } = useSelector((state) => state.merchant);
  const { categoriesCount, categories } = useSelector(
    (state) => state.category
  );
  const formRef = useRef(null);
  const [lang_id, setLang_id] = useState(null);
  const [merchantData, setMerchantData] = useState(initialMerchantData);
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [showUpdatePasswordPopup, setShowUpdatePasswordPopup] = useState(false);
  const shouldRedirect = useRef(true);

  useEffect(() => {
    if (!initialFormData || formType == FORM_TYPE.CREATE) return;

    let operational_hours = initialMerchantData.operational_hours;
    Object.entries(operational_hours)?.forEach(([day, operation]) => {
      if (!initialFormData?.operational_hours?.[day]) return;
      operational_hours[day] = {
        ...operation,
        ...initialFormData?.operational_hours[day],
      };
    });

    setMerchantData((prevData) => ({
      ...prevData,
      ...initialFormData,
      operational_hours: {
        ...operational_hours,
      },
      contact_info: {
        ...initialMerchantData.contact_info,
        ...initialFormData?.contact_info,
      },
      social_profiles: {
        ...initialMerchantData.social_profiles,
        ...initialFormData?.social_profiles,
      },
      location: {
        ...initialMerchantData.location,
        ...initialFormData?.location,
        coordinates:
          initialFormData?.location?.coordinates?.length == 2
            ? initialFormData?.location?.coordinates
            : [0, 0],
      },
      country_id: initialFormData?.country_id?.id || "",
      state_id: initialFormData?.state_id?.id || "",
      city_id: initialFormData?.city_id?.id || "",
      area_id: initialFormData?.area_id?.id || "",
      category_id: initialFormData?.category_id?.id || "",
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getMerchantTypes(1, 1000));
    dispatch(getMerchantTags());
    dispatch(getLanguages(1, 1000));
    dispatch(getBrands(1, 1000));
    dispatch(getCategories(1, 1000));
    dispatch(getFacilities(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = merchnatMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  const onChangeFormData = ({ name, value }) => {
    if (lang_id) {
      setMultiLangData((prevState) => ({ ...prevState, [name]: value }));
      return;
    }
    setMerchantData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeLocationInfo = (e) => {
    const { name, value } = e.target;
    let location = merchantData.location;
    if (name == "latitude" || name == "longitude") {
      let coordinates = [...merchantData.location.coordinates];
      const index = name == "latitude" ? 1 : 0;
      coordinates[index] = value;
      location["coordinates"] = coordinates;
    } else if (name == "address") {
      location["name"] = value;
      onChangeFormData({ name: "address", value });
    }
    onChangeFormData({ name: "location", value: location });
  };

  const handleSubmit = (e) => {
    e?.preventDefault(e);

    let temp_formData = {};

    if (!lang_id) {
      temp_formData = { ...merchantData };
      const { isValid, errors } = validateMerchantForm(formType, merchantData);

      if (!isValid) {
        errors.forEach((error) => dispatch(setAlert(error, AlertTypes.ERROR)));
        return;
      }
      // delete the properties from payload that are not allowed to send
      Object.keys(temp_formData).forEach((key) => {
        if (initialMerchantData[key] == undefined) {
          delete temp_formData[key];
        }
      });
    } else {
      temp_formData = { ...multiLangData, lang_id };
      Object.keys(temp_formData).forEach((key) => {
        if (initialMultiLangData[key] == undefined && key !== "lang_id") {
          delete temp_formData[key];
        }
      });
    }

    const formData = convertToFormData(temp_formData);
    handleFormSubmit(formData, shouldRedirect.current);
    shouldRedirect.current = true;
  };

  return (
    <div className="h-full w-full bg-white">
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/merchants")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to merchants
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Merchant`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate(-1),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => {
              submitFormHandler(formRef.current);
            },
            variant: "secondary",
          },
          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => {
                    shouldRedirect.current = false;
                    submitFormHandler(formRef.current);
                  },
                  variant: "primary",
                },
                {
                  text: "Update Password",
                  onClick: () => setShowUpdatePasswordPopup(true),
                  variant: "secondary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />

      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.MERCHANT}
          entityId={merchantData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}

      {/* password modal */}
      {formType === FORM_TYPE.UPDATE && showUpdatePasswordPopup ? (
        <UpdatePassword
          handleSubmit={({ password, confirmPassword }) =>
            dispatch(
              updateMerchantPassword(merchantData.id, {
                password,
                confirmPassword,
              })
            )
          }
          handleCancel={() => setShowUpdatePasswordPopup(false)}
        />
      ) : null}
      <div className="flex flex-col gap-4 p-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 p-4"
        >
          {/* Basic Information */}
          <FormSectionHeader text="Basic Information" />
          <BasicInformation
            merchantData={lang_id ? multiLangData : merchantData}
            onChangeFormData={onChangeFormData}
            lang_id={lang_id}
          />

          {/* Marketing */}
          <FormSectionHeader text="Marketing" />
          <MarketingSection
            merchantData={lang_id ? multiLangData : merchantData}
            onChangeFormData={onChangeFormData}
            lang_id={lang_id}
          />

          {!(formType === FORM_TYPE.UPDATE && lang_id) ? (
            <Fragment>
              <div class="flex h-full w-full flex-row items-start gap-2">
                <div class="flex h-5 items-center gap-4">
                  <label
                    for="most_loved"
                    class="text-base font-medium text-gray-900 dark:text-gray-300"
                  >
                    Most Loved
                  </label>
                  <input
                    id="most_loved"
                    type="checkbox"
                    checked={merchantData.is_most_loved}
                    onChange={() =>
                      onChangeFormData({
                        name: "is_most_loved",
                        value: !merchantData.is_most_loved,
                      })
                    }
                    className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-green-600 checked:bg-green-500 focus:ring-2 focus:ring-green-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-green-600"
                  />
                </div>
              </div>
              {/* Category*/}
              <div className="flex h-full w-full flex-col items-start gap-2">
                <label className="block text-base font-medium text-gray-800">
                  Category
                </label>
                <HeadlessSelectObject
                  title={
                    categories.length ? "Select Category" : "No category found"
                  }
                  options={categories.map(({ name, id }) => ({
                    name,
                    value: id,
                  }))}
                  selected={merchantData.category_id}
                  onSelect={(value) =>
                    onChangeFormData({ name: "category_id", value })
                  }
                />
              </div>

              {/* Cuisine Merchant Types */}
              <div className="w-full gap-2">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Cuisine Types
                </label>
                <MultiSelect
                  options={types.map((t) => ({ label: t.name, value: t.id }))}
                  selectedValues={merchantData.merchant_type_ids}
                  setSelectedValues={(values) =>
                    onChangeFormData({
                      name: "merchant_type_ids",
                      value: values,
                    })
                  }
                />
              </div>

              {/*Currency */}
              <div className="flex flex-col justify-between gap-2 md:flex-row">
                {/* <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Delivery Time
                  </label>
                  <input
                    onChange={({ target }) =>
                      onChangeFormData({
                        name: target.name,
                        value: Number(target.value),
                      })
                    }
                    required
                    type="number"
                    name="delivery_time"
                    value={merchantData.delivery_time}
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div> */}
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Base Currency
                  </label>
                  <select
                    required
                    name="currency"
                    value={merchantData.currency}
                    onChange={(e) => onChangeFormData(e.target)}
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  >
                    {Object.values(CURRENCY_OPTIONS).map((currencyOption) => (
                      <option value={currencyOption} className="capitalize">
                        {currencyOption}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Contact */}
              <FormSectionHeader text="Contact Information" />
              <ContactInfo
                merchantData={merchantData}
                onChangeFormData={onChangeFormData}
              />

              {/* Social Profiles */}
              <FormSectionHeader text="Social Profiles" />
              <SocialLinks
                merchantData={merchantData}
                onChangeFormData={onChangeFormData}
              />

              {/* Region */}
              <FormSectionHeader text="Region" />
              <MerchantRegion
                merchantData={merchantData}
                setMerchantData={setMerchantData}
                onChangeFormData={onChangeFormData}
              />

              {/* Address Latitude Longitude */}
              <FormSectionHeader text="Location" />
              <div className="flex flex-col justify-between gap-2 md:flex-row">
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Location Latitude
                  </label>
                  <input
                    required
                    type="text"
                    name="latitude"
                    value={merchantData.location.coordinates[1]}
                    onChange={onChangeLocationInfo}
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Location Longitude
                  </label>
                  <input
                    required
                    type="text"
                    name="longitude"
                    value={merchantData.location.coordinates[0]}
                    onChange={onChangeLocationInfo}
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>

              {/* Address */}
              <div className="flex flex-col justify-between gap-2 md:flex-row">
                <div className="w-full">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Address
                  </label>
                  <input
                    onChange={onChangeLocationInfo}
                    required
                    type="text"
                    name="address"
                    value={merchantData.address}
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>

              {/* Earning & Redemption */}
              <FormSectionHeader text="Earning & Redemption" />
              <EarningRedemtion
                merchantData={merchantData}
                onChangeFormData={onChangeFormData}
              />

              {/* Delivery Options */}
              <FormSectionHeader text="Delivery Options" />
              <DeliveryOption
                merchantData={merchantData}
                onChangeFormData={onChangeFormData}
              />

              {/* Opening Hours */}
              <FormSectionHeader text="Operational Hours" />
              <OperationHours
                timeZone={merchantData.timeZone}
                operational_hours={merchantData.operational_hours}
                onChangeFormData={onChangeFormData}
              />
            </Fragment>
          ) : null}

          {/* action buttons */}
          <div className="flex justify-between">
            <button
              disabled={loading}
              type="submit"
              // onClick={() => handleSubmit()}
              className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
            >
              {loading ? "Saving..." : "Submit"}
            </button>
            <button
              type="button"
              onClick={() => setShowCancelModal(true)}
              className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MerchantForm;
